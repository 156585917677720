import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { studyModel } from 'src/models/study.model';
import { AdminService } from 'src/services/admin.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudyListResolverService  {
  constructor(private _study: AdminService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<studyModel[]> {
    return this._study.getAllStudies();
  }

}
