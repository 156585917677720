import { Component, OnInit } from '@angular/core';
import { ParticipantService } from 'src/services/participant.service';
import { participantModel, participantattemptModel } from 'src/models/participant.model';
import { Router } from '@angular/router';
import { ConfigService } from 'src/services/config.service';

@Component({
  selector: 'app-admin-participant-attempt',
  templateUrl: './admin-participant-attempt.component.html',
  styleUrls: ['./admin-participant-attempt.component.css']
})
export class AdminParticipantAttemptComponent implements OnInit {
  dataTable: any;
  //dtOptions: DataTables.Settings = {};

  constructor(private participantService: ParticipantService, private router: Router, private configService: ConfigService) { }
  participantlist: participantattemptModel;
  userId: string;
  ngOnInit() {
  }

  getparticipantdetails(participantId, studyId) {
    this.participantService.getParticipantAttempt(participantId, studyId)
      .subscribe(_s => this.participantlist = _s);
  }

}
