import { Component, OnInit, ViewChild, Input, ViewChildren, QueryList, ElementRef, EventEmitter, Output } from '@angular/core';
import { questionModel, questions, mapQuestionStudy } from 'src/models/question.model';
import { AdminService } from 'src/services/admin.service';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigService } from 'src/services/config.service';
import { SelectItem } from 'primeng/api';
import { questionFilter } from 'src/app/model/gridFilter.model';
import { MessageService } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { NavigationTrackingService } from 'src/services/navigation-tracking.service';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-question-library',
  templateUrl: './question-library.component.html',
  styleUrls: ['./question-library.component.css'],
  providers: [MessageService]
})

export class QuestionLibraryComponent implements OnInit {
  @Input() data;
  @ViewChild('scrollTop', { static: true }) scrollTop: ElementRef;
  @ViewChild('dt', { static: true }) dt;
  @ViewChildren('question') question: QueryList<MultiSelect>;
  @ViewChildren('tag') tag: QueryList<MultiSelect>;
  @ViewChildren('study') study: QueryList<MultiSelect>;
  @Output() messageToParent = new EventEmitter<string>();
  public questionList: questions[] = [];
  public filterQuestionList: questions[] = [];
  public selectedQuestionList: questions[] = [];
  public QuestionsForDeleteLibrary: questionModel = new questionModel();;
  cols: any[];
  questionSelect: SelectItem[] = [];
  tagSelect: SelectItem[] = [];
  studySelect: SelectItem[] = [];
  questionFilter: questionFilter = new questionFilter();
  questionFilterHier: string[] = [];
  _mapQuestionStudy: mapQuestionStudy = new mapQuestionStudy();
  reference_number: number = 0;
  public studyId: number = 0;
  public isQuestinLibrary: boolean;
  selectedQuestions: questions[] = [];
  isEditDisabled: boolean = false;
  showMultiDelete: boolean = true;
  public selectedQuestionIds: number[] = [];
  isFromSessionStore = false;
  selectedTag: string[] = [];
  selectedStudy: string[] = [];
  isFirstNavigation = false;
  sessionStore: any;
  isClearQuesLibraryFilterOn = false;

  constructor(private admin: AdminService, private router: Router, private configService: ConfigService, private navService: NavigationTrackingService) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd),
      filter((event: NavigationEnd) => event.urlAfterRedirects === '/adminhome/QuestionLibrary'))
      .subscribe(event => {
        if (!this.navService.hasVisited(event.urlAfterRedirects)) {
          this.navService.markAsVisited(event.urlAfterRedirects);
          this.isFirstNavigation = true;
        }
      });
  }
  userId: string;
  ngOnInit() {
    sessionStorage.setItem("isAdminUser", "true");
    this.studyId = this.data;
    if (this.studyId !== undefined && this.studyId !== 0 && this.studyId !== null) {
      this.isQuestinLibrary = false;
    }
    else {
      this.isQuestinLibrary = true;
    }

    if (!this.isQuestinLibrary) {
      this.cols = [
        { field: 'question_Text', header: 'Question' },
        { field: 'study_Tag', header: 'Tags' },
        { field: 'study_Name', header: 'Studies' }
      ];
    }
    else {
      this.cols = [
        { field: 'question_Text', header: 'Question', width: '600px' },
        { field: 'study_Tag', header: 'Tags', width: '250px' },
        { field: 'study_Name', header: 'Studies', width: '225px' }
      ];
    }

    this.sessionStore = sessionStorage.getItem("UserPreference_QuestionLibrary");
    if (this.sessionStore !== null) {
      this.questionFilter = JSON.parse(this.sessionStore);
      this.isFromSessionStore = true;
      this.selectedTag = this.questionFilter.tag;
      this.selectedStudy = this.questionFilter.study;
    }
    this.getQuestionsLibrary();
  }

  ngAfterViewInit() {
  }
  ngOnDestroy() {

  }

  QuestionDel(question_ID) {
    if (confirm("Are you sure you want to delete the question?")) {
      this.admin.deleteQuestion(question_ID)
        .subscribe(r => {
          this.getQuestionsLibrary();
          alert("Question Deleted Successfully.");
        });
    }
  }

  onRowEditInit(question_id) {
    this.admin.getQuestionById(question_id)
      .subscribe(res => {
        this.QuestionsForDeleteLibrary = res;
      });
    this.admin.getQuestionsbyReference(question_id)
      .subscribe(res => {
        this.reference_number = res;
      });
  }

  onRowEditSave(question_id, question_text) {

    if (this.reference_number > 1) {
      if (confirm("This Question is refered more than one study . Are you sure you want to Edit the question?")) {
        this.QuestionsForDeleteLibrary.questionId = question_id;
        this.QuestionsForDeleteLibrary.questionText = question_text;
        this.admin.updateQuestion(this.QuestionsForDeleteLibrary)
          .subscribe(res => {
            alert("Question Edited Successfully.");
          });
      }
    }
    else {
      this.QuestionsForDeleteLibrary.questionId = question_id;
      this.QuestionsForDeleteLibrary.questionText = question_text;
      this.admin.updateQuestion(this.QuestionsForDeleteLibrary)
        .subscribe(res => {
          alert("Question Edited Successfully.");
        });
    }
  }

  onRowEditCancel(Qns: questionModel, index: number) {
    this.filterQuestionList[index].question_Text = this.QuestionsForDeleteLibrary.questionText;
  }

  getQuestionsLibrary() {
    this.admin.getQuestionsbyStudyandTag(0)
      .subscribe(res => {
        this.questionList = res;
        for (let key in this.questionList) {
          if (this.questionList.hasOwnProperty(key)) {
            if (this.questionList[key].study_Tag !== null && this.questionList[key].study_Tag !== "") {
              this.questionList[key].tag = this.questionList[key].study_Tag.split(',');
            }
            else {
              this.questionList[key].tag = ['(Blank)'];
            }
            if (this.questionList[key].study_Name !== null && this.questionList[key].study_Name !== "") {
              this.questionList[key].study = this.questionList[key].study_Name.split(',');
            }
            else {
              this.questionList[key].study = ['(Blank)'];
            }
          }
        }
        this.filterQuestionList = this.questionList.map(x => Object.assign({}, x));
        this.filterQuestions_ClientSide("");
        if (this.isFromSessionStore) {
          this.filterGridRecords_ClientSide(null, null, "tag");
          this.filterGridRecords_ClientSide(null, null, "study");
        }
      });
  }
  clearGridColumn_ClientSide(event, table, colname) {
    this.isClearQuesLibraryFilterOn = true;
    switch (colname) {
      case "tag":
        this.questionFilter.tag = [];
        this.tag.forEach(ds => {
          ds.value = null;
          ds.updateLabel();
        });
        break;
      case "study":
        this.questionFilter.study = [];
        this.study.forEach(ds => {
          ds.value = null;
          ds.updateLabel();
        });
        break;
    }
    this.questionFilterHier = this.questionFilterHier.filter(x => x !== colname);

    this.clearQuestionFilter_ClientSide(colname);
  }
  filterGridRecords_ClientSide(event, table, colname) {
    this.filterQuestionList = this.questionList.map(x => Object.assign({}, x));

    if(event !== null)
    {
      this.isFromSessionStore = false;
    }

    if (!this.questionFilterHier.includes(colname))
      this.questionFilterHier.push(colname);

    switch (colname) {
      case "tag":
        this.tag.forEach(ds => {
          if (ds.value !== undefined && ds.value !== null) {
            this.questionFilter.tag = ds.value;
            this.questionFilter.tag.concat(this.selectedTag);
          }
          ds.overlayVisible = false;
        });
        break;
      case "study":
        this.study.forEach(ds => {
          if (ds.value !== undefined && ds.value !== null) {
            this.questionFilter.study = ds.value;
            this.questionFilter.study.concat(this.selectedStudy);
          }
          ds.overlayVisible = false;
        });
        break;
    }

    if (this.questionFilter.tag.length !== 0) {
      this.filterQuestionList = this.filterQuestionList.filter(data => {
        return data.tag.findIndex(ta =>
          this.questionFilter.tag.findIndex(m => m === ta) > -1) > -1
      });
    }
    if (this.questionFilter.study.length !== 0) {
      this.filterQuestionList = this.filterQuestionList.filter(data => {
        return data.study.findIndex(ta =>
          this.questionFilter.study.findIndex(m => m === ta) > -1) > -1
      });
    }
    sessionStorage.setItem("UserPreference_QuestionLibrary", JSON.stringify(this.questionFilter));
    this.sessionStore = sessionStorage.getItem("UserPreference_QuestionLibrary");
    this.filterQuestions_ClientSide(colname);
  }
  clearQuestionFilter_ClientSide(colname) {

    this.filterQuestionList = this.questionList.map(x => Object.assign({}, x));

    if (this.questionFilter.tag.length !== 0) {
      this.filterQuestionList = this.filterQuestionList.filter(data => {
        return data.tag.findIndex(ta =>
          this.questionFilter.tag.findIndex(m => m === ta) > -1) > -1
      });
    }
    if (this.questionFilter.study.length !== 0) {
      this.filterQuestionList = this.filterQuestionList.filter(data => {
        return data.study.findIndex(ta =>
          this.questionFilter.study.findIndex(m => m === ta) > -1) > -1
      });
    }
    this.filterQuestions_ClientSide(colname);
    sessionStorage.setItem("UserPreference_QuestionLibrary", JSON.stringify(this.questionFilter));
  }

  filterQuestions_ClientSide(filterFor) {

    if (this.questionFilter.tag.length === 0)
      this.tagSelect = [];
    if (this.questionFilter.study.length === 0)
      this.studySelect = [];

    let _filterQuestionList = this.filterQuestionList.map(x => Object.assign({}, x));
    let tagArray = [];
    let studyArray = [];

    for (let key in _filterQuestionList) {
      if (_filterQuestionList.hasOwnProperty(key)) {
        tagArray.push(_filterQuestionList[key].study_Tag);
        studyArray.push(_filterQuestionList[key].study_Name);

        if (_filterQuestionList[key].study_Tag !== null) {
          _filterQuestionList[key].tag = _filterQuestionList[key].study_Tag.split(',');
          if (_filterQuestionList[key].study_Tag.split(',').length >= 1) {
            for (let k = 0; k < _filterQuestionList[key].study_Tag.split(',').length; k++) {
              if (_filterQuestionList[key].study_Tag.split(',')[k] !== "" && _filterQuestionList[key].study_Tag.split(',')[k] !== null)
                this.tagSelect.push({ label: _filterQuestionList[key].study_Tag.split(',')[k], value: _filterQuestionList[key].study_Tag.split(',')[k] });
            }
          }
          else {
            if (_filterQuestionList[key].study_Tag !== "" && _filterQuestionList[key].study_Tag !== null)
              this.tagSelect.push({ label: _filterQuestionList[key].study_Tag, value: _filterQuestionList[key].study_Tag });
          }
        }

        if (_filterQuestionList[key].study_Name !== null) {
          _filterQuestionList[key].study = _filterQuestionList[key].study_Name.split(',');
          if (_filterQuestionList[key].study_Name.split(',').length >= 1) {
            for (let k = 0; k < _filterQuestionList[key].study_Name.split(',').length; k++) {
              if (_filterQuestionList[key].study_Name.split(',')[k] !== "" && _filterQuestionList[key].study_Name.split(',')[k] !== null)
                this.studySelect.push({ label: _filterQuestionList[key].study_Name.split(',')[k], value: _filterQuestionList[key].study_Name.split(',')[k] });
            }
          }
          else {
            if (_filterQuestionList[key].study_Name !== "" && _filterQuestionList[key].study_Name !== null)
              this.studySelect.push({ label: _filterQuestionList[key].study_Name, value: _filterQuestionList[key].study_Name });
          }
        }
      }
    }

    this.tagSelect.push({ label: '(Blank)', value: '(Blank)' });
    this.studySelect.push({ label: '(Blank)', value: '(Blank)' });

    this.tagSelect = Array.from(this.tagSelect.reduce((m, t) => m.set(t.label, t), new Map()).values());
    this.studySelect = Array.from(this.studySelect.reduce((m, t) => m.set(t.label, t), new Map()).values());

    this.tagSelect = this.tagSelect.sort((a, b) => a.label.localeCompare(b.label));
    this.studySelect = this.studySelect.sort((a, b) => a.label.localeCompare(b.label));

    if (this.questionFilterHier.length > 0) {
      if (this.questionFilterHier[0] === "tag") {
        this.tagSelect = [];
      }
      else if (this.questionFilterHier[0] === "study") {
        this.studySelect = [];
      }

      for (let key in this.questionList) {
        if (this.questionList.hasOwnProperty(key)) {
          if (this.questionFilterHier[0] === "tag") {
            this.questionList[key].study_Tag = this.questionList[key].study_Tag === null ? "" : this.questionList[key].study_Tag;
            if (this.questionList[key].study_Tag !== null) {
              if (this.questionList[key].study_Tag.split(',').length >= 1) {
                for (let k = 0; k < this.questionList[key].study_Tag.split(',').length; k++) {
                  if (this.questionList[key].study_Tag.split(',')[k] !== "" && this.questionList[key].study_Tag.split(',')[k] !== null)
                    this.tagSelect.push({ label: this.questionList[key].study_Tag.split(',')[k], value: this.questionList[key].study_Tag.split(',')[k] });
                }
              }
              else {
                if (this.questionList[key].study_Tag !== "" && this.questionList[key].study_Tag !== null)
                  this.tagSelect.push({ label: _filterQuestionList[key].study_Tag, value: _filterQuestionList[key].study_Tag });
              }
            }
          }
          else if (this.questionFilterHier[0] === "study") {
            this.questionList[key].study_Name = this.questionList[key].study_Name === null ? "" : this.questionList[key].study_Name;
            if (this.questionList[key].study_Name !== null) {
              if (this.questionList[key].study_Name.split(',').length >= 1) {
                for (let k = 0; k < this.questionList[key].study_Name.split(',').length; k++) {
                  if (this.questionList[key].study_Name.split(',')[k] !== "" && this.questionList[key].study_Name.split(',')[k] !== null)
                    this.studySelect.push({ label: this.questionList[key].study_Name.split(',')[k], value: this.questionList[key].study_Name.split(',')[k] });
                }
              }
              else {
                if (this.questionList[key].study_Name !== "" && this.questionList[key].study_Name !== null)
                  this.studySelect.push({ label: _filterQuestionList[key].study_Name, value: _filterQuestionList[key].study_Name });
              }
            }
          }
        }
      }

      this.tagSelect.push({ label: '(Blank)', value: '(Blank)' });
      this.studySelect.push({ label: '(Blank)', value: '(Blank)' });
      this.tagSelect = Array.from(this.tagSelect.reduce((m, t) => m.set(t.label, t), new Map()).values());
      this.studySelect = Array.from(this.studySelect.reduce((m, t) => m.set(t.label, t), new Map()).values());
      this.tagSelect = this.tagSelect.sort((a, b) => a.label.localeCompare(b.label));
      this.studySelect = this.studySelect.sort((a, b) => a.label.localeCompare(b.label));
    }

    if(!this.isClearQuesLibraryFilterOn) {
      this.tag.forEach(ds => {
        if (this.isFromSessionStore) {
          ds.value = this.selectedTag;
        } else {
          if (this.isFirstNavigation || this.sessionStore === null) {
            ds.value = this.tagSelect.map(x => x.value);
          } else {
            ds.value = this.questionFilter.tag;
          }
        }
        ds.updateLabel();
      });
  
      this.study.forEach(ds => {
        if (this.isFromSessionStore) {
          ds.value = this.selectedStudy;
        } else {
          if (this.isFirstNavigation || this.sessionStore === null) {
            ds.value = this.studySelect.map(x => x.value);
          } else {
            ds.value = this.questionFilter.study;
          }
        }
        ds.updateLabel();
      });
    }   
    this.isClearQuesLibraryFilterOn = false;
    this.isFirstNavigation = false;
  }
  checkBoxClick(event, question_ID) {

    let updateItem = this.questionList.filter(x => x.question_ID === question_ID)[0];
    let index = this.questionList.indexOf(updateItem);

    this.questionList[index].checked = event.target.checked;
  }
  selectAll(event) {
    for (let i = 0; i < this.questionList.length; i++) {
      let id = "checkBox_" + this.questionList[i].question_ID;
      (<HTMLInputElement>document.getElementById(id)).checked = event.target.checked;
      this.questionList[i].checked = event.target.checked;
    }
  }
  SaveQuestions() {
    let questionId: number[] = [];
    if (this.selectedQuestionList.length === 0) {
      alert('Select atleast one question!');
      return false;
    }
    for (let i = 0; i < this.selectedQuestionList.length; i++) {
      questionId.push(this.selectedQuestionList[i].question_ID);
    }
    if (this.questionList.length > 0) {
      this._mapQuestionStudy.questionId = questionId;
      this._mapQuestionStudy.studyId = this.studyId;

      this.admin.mapQuestionstoStudy(this._mapQuestionStudy)
        .subscribe(res => {
          alert('Question and Answer Added Successfully');
          this.scrollTop.nativeElement.focus();
          this.selectedQuestionList = [];
          this.getQuestionsLibrary();
          this.messageToParent.emit("true");
        });
    }

  }
  paginate(event) {
    this.scrollTop.nativeElement.focus();
  }

  getResponseFromChild(response: string) {
    this.messageToParent.emit("true");
  }

  onRowSelect() {
    this.selectedQuestionIds = this.selectedQuestionList.map(q => q.question_ID);
    this.updateButtonState();
  }

  onRowUnselect() {
    this.selectedQuestionIds = this.selectedQuestionList.map(q => q.question_ID);
    this.updateButtonState();
  }

  updateButtonState() {
    this.isEditDisabled = this.selectedQuestionList.length > 1;
    this.showMultiDelete = this.selectedQuestionList.length < 1;
  }

  onHeaderCheckboxToggle(event) {
    this.isEditDisabled = event.checked;
    this.showMultiDelete = !event.checked;
  }

  DeleteSelectedQuestions() {
    if (confirm("Are you sure you want to delete the selected questions?")) {

      this.admin.deleteQuestions(this.selectedQuestionIds)
        .subscribe(resp => {
          this.getQuestionsLibrary();
          alert("Question Deleted Successfully.");
          this.selectedQuestionList = [];
          this.updateButtonState();
        });
    }
  }
}
