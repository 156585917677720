// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notFound {
    padding: 80px 0;
}
.basicBtn {
    border-radius: 4px;
    color: #297dfd;
}
.basicBtn:hover {
    color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin-not-found/admin-not-found.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,cAAc;AAClB","sourcesContent":[".notFound {\r\n    padding: 80px 0;\r\n}\r\n.basicBtn {\r\n    border-radius: 4px;\r\n    color: #297dfd;\r\n}\r\n.basicBtn:hover {\r\n    color: #000000;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
