export class DemographicsModel {
   public question: string;
   public operator:string;
   public answer1: number;
   public answer2: number;
   public IncludeCondition: boolean;
   public IsBetween: boolean;
}
export class stratificationModel {
  public sno: number;
  public studyId: number;
  public questionText: string;
  public ageQuestionId: number;
  public ageAnswerType: string;
  public ageFrom: number;
  public ageTo: number;
  public ageFromId: number;
  public ageToId: number;
  public weightQuestionId: number;
  public weightAnswerType: string;
  public weightFrom: number;
  public weightTo: number;
  public weightFromId: number;
  public weightToId: number;
  public bmiQuestionId: number;
  public bmiAnswerType: string;
  public bmiFrom: number;
  public bmiTo: number;
  public bmiFromId: number;
  public bmiToId: number;
  public genderQuestionId: number;
  public genderAnswerType: string;
  public male: boolean;
  public female: boolean;
  public genderId: number;
  public stGroup: number;
  public ageValidate: boolean;
  public weightValidate: boolean;
  public bmiValidate: boolean;
}
export class questionStratification {
  public questionId: number;
  public questionText: string;
  public questionCategory: string;
  public toolTip: string;
}
export class StraAdditional {
  
  public strno: number;
  public studyId: number;
  public PrName: string;
  public fromValue: number;
  public toValue: number;
  public questionId: number;
  public answerFromId: number;
  public answerToId: number;
  public grpNo: number;
  public customStrat: boolean;  
}
