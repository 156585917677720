import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { sessionData } from 'src/models/data.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public onUnload: boolean = true;
  public _sessionData: sessionData = new sessionData();
  constructor() { }
  getData() {
    return this._sessionData;
  }
  setData(_studyId: number) {
    this._sessionData.studyId = _studyId;
  }
  cleartData() {
    this._sessionData.studyId = null;
  }
}
