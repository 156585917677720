import { questionModel, answersModel } from './question.model';

export class questionstudyModel {
  question: questionModel;
  studyQuestion: studyQuestionModel;
  answer: answersModel[];
}
export class studyQuestionModel {
  studyId: number;
  questionOrder: number;
  isPrescreener: boolean = true;
  isActive: boolean = true;
  stGroup: number;
}
