export class gridFilter {

}
export class studyFilter {
  studyNumber: string[] = [];
  tag: string[] = [];
  status: string[] = [];
}
export class filterPrescreened {
  participantName: string[] = [];
  studyNumber: string[] = [];
  age: number[] = [];
  gender: string[] = [];
  dateCreated: string[] = [];
}
export class applicantsFilter {
  participantName: string[] = [];
  phone: string[] = [];
  whentocall: string[] = [];
  age: number[] = [];
  gender: string[] = [];
  studyNumber: string[] = [];
  status: string[] = [];
}
export class questionFilter {
  question: string[] = [];
  tag: string[] = [];
  study: string[] = [];
}
