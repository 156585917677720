import { Component, OnInit, ViewChildren, QueryList, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from 'src/services/admin.service';
import { ConfigService } from 'src/services/config.service';
import { studyModel } from 'src/models/study.model';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { participantModel } from 'src/models/participant.model';
import { ParticipantService } from 'src/services/participant.service';
import { filterPrescreened, studyFilter } from 'src/app/model/gridFilter.model';
import { DatePipe } from '@angular/common';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/services/data.service';
import { MultiSelect } from 'primeng/multiselect';
import { NavigationTrackingService } from 'src/services/navigation-tracking.service';
import { ExcelExportService } from 'src/app/service/excel-export.service';
import { SelectItem } from 'primeng/api/selectitem';
@Component({
  selector: 'app-adminhome',
  templateUrl: './admin_home.component.html',
  styleUrls: ['./admin_home.component.css']
})


export class AdminHomeComponent implements OnInit {

  @ViewChild('scrollTop', { static: true }) scrollTop: ElementRef;
  @ViewChild('dt', { static: true }) dt;
  @ViewChildren('studyNumberClear') clearstudyNumber: QueryList<MultiSelect>;
  @ViewChildren('studyTagClear') clearTag: QueryList<MultiSelect>;
  @ViewChildren('studyStatusClear') clearStatus: QueryList<MultiSelect>;
  @ViewChildren('studyNameClear') clearStudy: QueryList<MultiSelect>;
  @ViewChildren('nameClear') clearName: QueryList<MultiSelect>;
  @ViewChildren('ageClear') clearage: QueryList<MultiSelect>;
  @ViewChildren('genderClear') cleargender: QueryList<MultiSelect>;
  @ViewChildren('dateCompletedClear') cleardateCompleted: QueryList<MultiSelect>;

  availablestudies: studyModel[] = [];
  deleteModel: studyModel;
  validity: boolean;
  userId: string;
  cols: any[];
  tags: SelectItem[] = [];
  studyName_studyGrid: SelectItem[] = [];
  status: SelectItem[] = [
    { label: 'Inactive', value: false },
    { label: 'Active', value: true }
  ];
  aa: SelectItem;
  cols_prescreened: any[];
  studynumber: SelectItem[] = [];
  age: SelectItem[] = [];
  gender: SelectItem[] = [];
  dateCompleted: SelectItem[] = [];
  participantlist: participantModel[];
  filteredParticipantlist: participantModel[];
  prescreenedFilter: filterPrescreened = new filterPrescreened();
  prescreenedFilterHier: string[] = [];
  filterAvailablestudies: studyModel[] = [];
  studyFilter: studyFilter = new studyFilter();
  studyFilterHier: string[] = [];
  selectedStudyNumber: string[] = [];
  selectedTag: string[] = [];
  selectedStatus: boolean[] = [];
  selectedStudyName: string[] = [];
  selectedAge: number[] = [];
  selectedGender: string[] = [];
  selectedDateCompleted: string[] = [];
  isFromSessionStore = false;
  isFirstVisit = false;
  datePipe: DatePipe = new DatePipe('en-US');
  checkedStudyNumber: any[] = [];
  checkedStudyTags: any[] = [];
  checkedStudyStatus: any[] = [];
  sessionStoreStudy: any;
  sessionStorePrescreened: any;
  isClearStudyFilterOn = false;
  isClearPrescreenedFilterOn = false;
  participantListForExport: any[] = [];
  questionTextStudy: any[] = [];
  participantIdList: string;
  questionIdList: any[] = [];

  constructor(private studyService: AdminService, private cookieService: CookieService, private navService: NavigationTrackingService,
    private configService: ConfigService, private router: Router, private _route: ActivatedRoute, private participantService: ParticipantService, private dataService: DataService, private exportExcel: ExcelExportService) {
    this.availablestudies = _route.snapshot.data['studyList'];
    if (this.availablestudies !== undefined) {
      for (let key in this.availablestudies) {
        if (this.availablestudies.hasOwnProperty(key)) {
          if (this.availablestudies[key].studyTag !== null && this.availablestudies[key].studyTag !== "") {
            this.availablestudies[key].tag = this.availablestudies[key].studyTag.split(',');
          }
          else {
            this.availablestudies[key].tag = [];
          }
        }
      }
      this.filterAvailablestudies = this.availablestudies.filter(x => x.isPublished === true).map(x => Object.assign({}, x));
    }
    this.router.events.pipe(filter(event => event instanceof NavigationEnd),
      filter((event: NavigationEnd) => event.urlAfterRedirects === '/adminhome'))
      .subscribe(event => {
        if (!this.navService.hasVisited(event.urlAfterRedirects)) {
          this.navService.markAsVisited(event.urlAfterRedirects);
          this.isFirstVisit = true;
          sessionStorage.clear();
        }
      });
  }
  displayNewStudyCall() {
    this.dataService.cleartData()
    this.router.navigate(['/adminhome/studydetail/'], { fragment: 'display', skipLocationChange: true });
  }
  displayCall(studyID) {
    this.router.navigate(['/adminhome/studydetail/', studyID], { fragment: 'display', skipLocationChange: true });
  }

  stratificationCall(studyID) {
    this.router.navigate(['/adminhome/studydetail/', studyID], { fragment: 'stratification', skipLocationChange: true });
  }

  questionCall(studyID) {
    this.router.navigate(['/adminhome/studydetail/', studyID], { fragment: 'question', skipLocationChange: true });
  }

  ngOnInit() {
    sessionStorage.setItem("isAdminUser", "true");
    this.sessionStoreStudy = sessionStorage.getItem("UserPreference_StudiesLibrary");
    if (this.sessionStoreStudy !== null) {
      this.studyFilter = JSON.parse(this.sessionStoreStudy);
      this.isFromSessionStore = true;
      this.selectedStudyNumber = this.studyFilter.studyNumber;
      this.selectedTag = this.studyFilter.tag;
      this.selectedStatus = this.studyFilter.status;
    }

    this.sessionStorePrescreened = sessionStorage.getItem("UserPreference_PreScreenedLibrary");
    if (this.sessionStorePrescreened !== null) {
      this.prescreenedFilter = JSON.parse(this.sessionStorePrescreened);
      this.isFromSessionStore = true;
      this.selectedStudyName = this.prescreenedFilter.studyNumber;
      this.selectedAge = this.prescreenedFilter.age;
      this.selectedGender = this.prescreenedFilter.gender;
      this.selectedDateCompleted = this.prescreenedFilter.dateCreated;
    }

    this.getPreScreenedApplicants();

    this.cols = [
      { field: 'studyName', header: 'Study Number', width: '215px' },
      { field: 'dir', header: '', width: '300px' },
      { field: 'studyTag', header: 'Tags', width: '325px' },
      { field: 'isPublished', header: 'Status', width: '150px' }
    ];
    this.cols_prescreened = [
      { field: 'name', header: 'Applicant Name', width: '250px' },
      { field: 'studyName', header: 'Study Number', width: '200px' },
      { field: 'age', header: 'Age', width: '125px' },
      { field: 'gender', header: 'Gender', width: '150px' },
      { field: 'createdDate', header: 'Date Completed', width: '215px' }
    ];

    this.studyFilterHier.push('studyStatus');
    this.studyFilter.status = [true];

    this.filterStudies_ClientSide("");
    if (this.isFromSessionStore) {
      this.filterGridRecords_ClientSide(null, null, "studyNumber");
      this.filterGridRecords_ClientSide(null, null, "studyTag");
      this.filterGridRecords_ClientSide(null, null, "studyStatus");
    }
  }


  getAvailableStudies() {
    this.studyService.getAllStudies()
      .subscribe(_s => this.availablestudies = _s);
  }
  deleteStudy(Id: number) {
    if (confirm("Are you sure you want to Delete the Study")) {
      this.studyService.deleteStudy(Id)
        .subscribe(res => {
          alert("Study Deleted Successfully");
          this.getAvailableStudies();
        });
    };
  }

  clearGridColumn_ClientSide(event, table, colname) {
    this.isClearStudyFilterOn = true;
    switch (colname) {
      case "studyNumber":
        this.studyFilter.studyNumber = [];
        this.clearstudyNumber.forEach(ds => {
          ds.value = null;
          ds.updateLabel();
        });
        break;
      case "studyTag":
        this.studyFilter.tag = [];
        this.clearTag.forEach(ds => {
          ds.value = null;
          ds.updateLabel();
        });
        break;
      case "studyStatus":
        this.studyFilter.status = [];
        this.clearStatus.forEach(ds => {
          ds.value = null;
          this.checkedStudyStatus = [];
          ds.updateLabel();
        });
        break;
    }
    this.prescreenedFilterHier = this.prescreenedFilterHier.filter(x => x !== colname);
    this.clearPrescreenFilter_ClientSide(colname);
  }

  filterGridRecords_ClientSide(event, table, colname) {
    this.filterAvailablestudies = this.availablestudies.map(x => Object.assign({}, x));

    if (!this.studyFilterHier.includes(colname))
      this.studyFilterHier.push(colname);
    if (event !== null) {
      switch (colname) {
        case "studyNumber":
          this.clearstudyNumber.forEach(ds => {
            if (ds.value !== null && ds.value !== undefined) {
              this.studyFilter.studyNumber = ds.value;

              if (this.studyFilter.studyNumber.length !== 0) {
                this.filterAvailablestudies = this.filterAvailablestudies
                  .filter(x => this.studyFilter.studyNumber.includes(x.studyName));
              }
            }
            ds.overlayVisible = false;
          });
          break;
        case "studyTag":
          this.clearTag.forEach(ds => {
            if (ds.value !== null && ds.value !== undefined) {
              this.studyFilter.tag = ds.value;
              this.filterAvailablestudies = this.filterAvailablestudies.filter(data => {
                return data.tag.findIndex(ta =>
                  this.studyFilter.tag.findIndex(m => m === ta) > -1) > -1
              });
            }            
            ds.overlayVisible = false;
          });
          break;
        case "studyStatus":
          this.clearStatus.forEach(ds => {
            if (ds.value !== null && ds.value !== undefined) {
              this.studyFilter.status = ds.value;
              this.filterAvailablestudies = this.availablestudies.filter(x => this.studyFilter.status.includes(x.isPublished));
            }
            ds.overlayVisible = false;
          });
          break;
      }
    }

    if (this.studyFilter.studyNumber.length !== 0) {
      this.filterAvailablestudies = this.filterAvailablestudies
        .filter(x => this.studyFilter.studyNumber.includes(x.studyName));
    }

    if (this.studyFilter.tag.length !== 0) {
      this.filterAvailablestudies = this.filterAvailablestudies.filter(data => {
        return data.tag.findIndex(ta =>
          this.studyFilter.tag.findIndex(m => m === ta) > -1) > -1
      });
    }

    if (this.studyFilter.status.length !== 0) {
      this.filterAvailablestudies = this.filterAvailablestudies
        .filter(x => this.studyFilter.status.includes(x.isPublished));
    }

    sessionStorage.setItem("UserPreference_StudiesLibrary", JSON.stringify(this.studyFilter));
    this.sessionStoreStudy = sessionStorage.getItem("UserPreference_StudiesLibrary");
    this.selectedStudyNumber = this.studyFilter.studyNumber;
    this.selectedTag = this.studyFilter.tag;
    this.selectedStatus = this.studyFilter.status;
    this.filterStudies_ClientSide(colname);
  }
  clearPrescreenFilter_ClientSide(colname) {

    this.filterAvailablestudies = this.availablestudies.map(x => Object.assign({}, x));

    if (this.studyFilter.studyNumber.length !== 0) {
      this.filterAvailablestudies = this.filterAvailablestudies
        .filter(x => this.studyFilter.studyNumber.includes(x.studyName));
    }
    if (this.studyFilter.tag.length !== 0) {
      this.filterAvailablestudies = this.filterAvailablestudies.filter(data => {
        return data.tag.findIndex(ta =>
          this.studyFilter.tag.findIndex(m => m === ta) > -1) > -1
      });
    }
    if (this.studyFilter.status.length !== 0) {
      this.filterAvailablestudies = this.filterAvailablestudies
        .filter(x => this.studyFilter.status.includes(x.isPublished));
    }
    this.filterStudies_ClientSide(colname);
    sessionStorage.setItem("UserPreference_StudiesLibrary", JSON.stringify(this.studyFilter));
  }

  filterStudies_ClientSide(filterFor) {
    if (this.studyFilter.studyNumber.length === 0)
      this.studyName_studyGrid = [];
    if (this.studyFilter.tag.length === 0)
      this.tags = [];
    if (this.studyFilter.status.length === 0)
      this.status = [];

    let _filteredStudylist = this.filterAvailablestudies.map(x => Object.assign({}, x));
    let tagArray = [];

    for (let key in _filteredStudylist) {
      if (_filteredStudylist.hasOwnProperty(key)) {
        tagArray.push(_filteredStudylist[key].studyTag);

        this.studyName_studyGrid.push({ label: _filteredStudylist[key].studyName, value: _filteredStudylist[key].studyName })
        if (_filteredStudylist[key].studyTag !== null) {
          _filteredStudylist[key].tag = _filteredStudylist[key].studyTag.split(',');
          if (_filteredStudylist[key].studyTag.split(',').length >= 1) {
            for (let k = 0; k < _filteredStudylist[key].studyTag.split(',').length; k++) {
              this.tags.push({ label: _filteredStudylist[key].studyTag.split(',')[k], value: _filteredStudylist[key].studyTag.split(',')[k] });
            }
          }
          else {
            this.tags.push({ label: _filteredStudylist[key].studyTag, value: _filteredStudylist[key].studyTag });
          }
        }
      }
    }

    this.studyName_studyGrid = Array.from(this.studyName_studyGrid.reduce((m, t) => m.set(t.label, t), new Map()).values());
    this.tags = Array.from(this.tags.reduce((m, t) => m.set(t.label, t), new Map()).values());

    this.studyName_studyGrid = this.studyName_studyGrid.sort((a, b) => a.label.localeCompare(b.label));
    this.tags = this.tags.sort((a, b) => a.label.localeCompare(b.label));

    if (this.studyFilterHier.length > 0) {
      if (this.studyFilterHier[0] === "studyNumber") {
        this.studyName_studyGrid = [];
      }
      else if (this.studyFilterHier[0] === "studyTag") {
        this.tags = [];
      }
      else if (this.studyFilterHier[0] === "studyStatus") {
        this.status = [];
      }

      for (let key in this.availablestudies) {
        if (this.availablestudies.hasOwnProperty(key)) {
          if (this.studyFilterHier[0] === "studyNumber") {
            if (this.availablestudies[key].studyName !== null && this.availablestudies[key].studyName.toString() !== "")
              this.studyName_studyGrid.push({ label: this.availablestudies[key].studyName, value: this.availablestudies[key].studyName });
          }
          else if (this.studyFilterHier[0] === "studyTag") {
            this.availablestudies[key].studyTag = this.availablestudies[key].studyTag === null ? "" : this.availablestudies[key].studyTag;
            if (this.availablestudies[key].studyTag !== null) {
              this.availablestudies[key].tag = this.availablestudies[key].studyTag.split(',');
              if (this.availablestudies[key].studyTag.split(',').length >= 1) {
                for (let k = 0; k < this.availablestudies[key].studyTag.split(',').length; k++) {
                  this.tags.push({ label: this.availablestudies[key].studyTag.split(',')[k], value: this.availablestudies[key].studyTag.split(',')[k] });
                }
              }
              else {
                this.tags.push({ label: _filteredStudylist[key].studyTag, value: _filteredStudylist[key].studyTag });
              }
            }
          }
          else if (this.studyFilterHier[0] === "studyStatus") {
            this.availablestudies[key].isPublished = this.availablestudies[key].isPublished === null ? false : this.availablestudies[key].isPublished;
            this.status.push({ label: this.availablestudies[key].isPublished === true ? "Active" : "Inactive", value: this.availablestudies[key].isPublished });
          }
        }
      }
    }

    this.studyName_studyGrid = Array.from(this.studyName_studyGrid.reduce((m, t) => m.set(t.label, t), new Map()).values());
    this.tags = Array.from(this.tags.reduce((m, t) => m.set(t.label, t), new Map()).values());
    this.status = Array.from(this.status.reduce((m, t) => m.set(t.label, t), new Map()).values());

    this.studyName_studyGrid = this.studyName_studyGrid.sort((a, b) => a.label.localeCompare(b.label));
    this.tags = this.tags.sort((a, b) => a.value - b.value)
    this.status = this.status.sort((a, b) => a.value - b.value)

    if (!this.isClearStudyFilterOn) {
      if (this.isFromSessionStore) {
        this.checkedStudyNumber = this.selectedStudyNumber;
        this.checkedStudyTags = this.selectedTag;
        this.checkedStudyStatus = this.selectedStatus;
      } else {
        if (this.isFirstVisit || this.sessionStoreStudy === null) {
          this.checkedStudyNumber = this.studyName_studyGrid.map(x => x.value);
          this.checkedStudyTags = this.tags.map(x => x.value);
          this.checkedStudyStatus = [true];
        } else {
          this.checkedStudyNumber = this.studyFilter.studyNumber;
          this.checkedStudyTags = this.studyFilter.tag;
          this.checkedStudyStatus = this.studyFilter.status;
        }
      }
      this.isClearStudyFilterOn = false;
    }
  }

  /* Pre-Screened grid filter methods started */
  getFilters(colname, clickType) {
    if (this.prescreenedFilterHier.length > 0) {

      if (this.prescreenedFilterHier[0] == "studyName" && (colname !== "studyName" || clickType === "clear")) {
        this.studynumber = [];
        let prescreenedFilterPrimary = new filterPrescreened();
        this.participantService.getPrescreenedStudynumberFilter(prescreenedFilterPrimary)
          .subscribe(response => {
            this.studynumber = [];
            for (let key in response) {
              if (response.hasOwnProperty(key)) {
                this.studynumber.push({ label: response[key].studyName, value: response[key].studyName })
              }
            }
          });
      }
      else if (this.prescreenedFilterHier[0] === "age" && (colname !== "age" || clickType === "clear")) {
        this.age = [];
        let prescreenedFilterPrimary = new filterPrescreened();

        this.participantService.getPrescreenedAgeFilter(prescreenedFilterPrimary)
          .subscribe(response => {
            this.age = [];
            for (let key in response) {
              if (response.hasOwnProperty(key)) {
                if (response[key].age !== null)
                  this.age.push({ label: response[key].age === -2 ? '0' : response[key].age.toString(), value: response[key].age });
              }
            }
            this.age = Array.from(this.age.reduce((m, t) => m.set(t.label, t), new Map()).values());
          });
      }
      else if (this.prescreenedFilterHier[0] === "gender" && (colname !== "gender" || clickType === "clear")) {
        this.gender = [];

        this.gender = [
          { label: 'Female', value: 'F' },
          { label: 'Male', value: 'M' }
        ];
      }
      else if (this.prescreenedFilterHier[0] === "createdDate" && (colname !== "createdDate" || clickType === "clear")) {
        this.status = [];
        let prescreenedFilterPrimary = new filterPrescreened();
        this.participantService.getPrescreenedDateCompletedFilter(prescreenedFilterPrimary)
          .subscribe(response => {
            this.dateCompleted = [];
            for (let key in response) {
              if (response.hasOwnProperty(key)) {
                if (response[key].createdDate === null || response[key].createdDate === "") {
                  this.dateCompleted.push({ label: "(Blank)", value: null });
                }
                else {
                  this.dateCompleted.push({ label: this.datePipe.transform(response[key].createdDate, 'dd MMM yyyy'), value: this.datePipe.transform(response[key].createdDate, 'dd MMM yyyy') });
                }
              }
            }
          });
      }
      if (this.prescreenedFilter.age.includes(0) && !this.prescreenedFilter.age.includes(null))
        this.prescreenedFilter.age.push(null);

      if (this.prescreenedFilterHier[0] !== "age" && (colname !== "age" || clickType === "clear")) {
        this.participantService.getPrescreenedAgeFilter(this.prescreenedFilter)
          .subscribe(response => {
            this.age = [];
            for (let key in response) {
              if (response.hasOwnProperty(key)) {
                if (response[key].age !== null)
                  this.age.push({ label: response[key].age === -2 ? '0' : response[key].age.toString(), value: response[key].age });
              }
            }
            this.age = Array.from(this.age.reduce((m, t) => m.set(t.label, t), new Map()).values());
          });
      }
      if (this.prescreenedFilterHier[0] !== "gender" && (colname !== "gender" || clickType === "clear")) {

        this.gender = [
          { label: 'Female', value: 'F' },
          { label: 'Male', value: 'M' }
        ];
      }
      if (this.prescreenedFilterHier[0] !== "studyName" && (colname !== "studyName" || clickType === "clear")) {
        this.participantService.getPrescreenedStudynumberFilter(this.prescreenedFilter)
          .subscribe(response => {
            this.studynumber = [];
            for (let key in response) {
              if (response.hasOwnProperty(key)) {
                this.studynumber.push({ label: response[key].studyName, value: response[key].studyName })
              }
            }
          });
      }
      if (this.prescreenedFilterHier[0] !== "createdDate" && (colname !== "createdDate" || clickType === "clear")) {
        this.participantService.getPrescreenedDateCompletedFilter(this.prescreenedFilter)
          .subscribe(response => {
            this.dateCompleted = [];
            for (let key in response) {
              if (response.hasOwnProperty(key)) {
                if (response[key].createdDate === null || response[key].createdDate === "") {
                  this.dateCompleted.push({ label: "(Blank)", value: null });
                }
                else {
                  this.dateCompleted.push({ label: this.datePipe.transform(response[key].createdDate, 'dd MMM yyyy'), value: this.datePipe.transform(response[key].createdDate, 'dd MMM yyyy') });
                }
              }
            }
          });
      }
    }
    else {

      this.prescreenedFilter = new filterPrescreened();
      this.gender = [
        { label: "Female", value: "F" },
        { label: "Male", value: "M" }
      ];
      this.participantService.getPrescreenedAgeFilter(this.prescreenedFilter)
        .subscribe(response => {
          this.age = [];
          for (let key in response) {
            if (response.hasOwnProperty(key)) {
              if (response[key].age !== null)
                this.age.push({ label: response[key].age === -2 ? '0' : response[key].age.toString(), value: response[key].age });
            }
          }
          this.age = Array.from(this.age.reduce((m, t) => m.set(t.label, t), new Map()).values());
        });
      this.participantService.getPrescreenedStudynumberFilter(this.prescreenedFilter)
        .subscribe(response => {
          this.studynumber = [];
          for (let key in response) {
            if (response.hasOwnProperty(key)) {
              this.studynumber.push({ label: response[key].studyName, value: response[key].studyName })
            }
          }
        });
      this.participantService.getPrescreenedDateCompletedFilter(this.prescreenedFilter)
        .subscribe(response => {
          this.dateCompleted = [];
          for (let key in response) {
            if (response.hasOwnProperty(key)) {
              if (response[key].createdDate === null || response[key].createdDate === "") {
                this.dateCompleted.push({ label: "(Blank)", value: null });
              }
              else {
                this.dateCompleted.push({ label: this.datePipe.transform(response[key].createdDate, 'dd MMM yyyy'), value: this.datePipe.transform(response[key].createdDate, 'dd MMM yyyy') });
              }
            }
          }
        });
    }
    this.filterApplicants(colname);
  }

  /* Clear Study Column */
  clearGridColumn(event, table, colname) {
    this.isClearPrescreenedFilterOn = true;
    switch (colname) {
      case "studyName":
        this.prescreenedFilter.studyNumber = [];
        this.clearStudy.forEach(ds => {
          ds.value = null;
          ds.updateLabel();
        });
        break;
      case "age":
        this.prescreenedFilter.age = [];
        this.clearage.forEach(ds => {
          ds.value = null;
          ds.updateLabel();
        });
        break;
      case "gender":
        this.prescreenedFilter.gender = [];
        this.cleargender.forEach(ds => {
          ds.value = null;
          ds.updateLabel();
        });
        break;
      case "createdDate":
        this.prescreenedFilter.dateCreated = [];
        this.cleardateCompleted.forEach(ds => {
          ds.value = null;
          ds.updateLabel();
        });
        break;
    }
    this.prescreenedFilterHier = this.prescreenedFilterHier.filter(x => x !== colname);

    this.clearPrescreenFilter(colname);
    table.filter('', colname, 'contains');
    sessionStorage.setItem("UserPreference_PreScreenedLibrary", JSON.stringify(this.prescreenedFilter));
  }
  getPreScreenedApplicants() {
    if (this.prescreenedFilter.age.includes(0) && !this.prescreenedFilter.age.includes(null))
      this.prescreenedFilter.age.push(null);

    this.participantService.getPrescreenedApplicants(this.prescreenedFilter)
      .subscribe(response => {
        this.participantlist = response;
        this.filteredParticipantlist = response;
        this.getFilters("", "");
      });
  }

  filterGridRecords(event, table, colname) {
    if (!this.prescreenedFilterHier.includes(colname))
      this.prescreenedFilterHier.push(colname);

    if (event !== null) {
      this.isFromSessionStore = false;
    }

    switch (colname) {
      case "studyName":
        this.clearStudy.forEach(ds => {
          this.prescreenedFilter.studyNumber = ds.value;
          ds.overlayVisible = false;
        });
        break;
      case "age":
        this.clearage.forEach(ds => {
          this.prescreenedFilter.age = ds.value;
          ds.overlayVisible = false;
        });
        break;
      case "gender":
        this.cleargender.forEach(ds => {
          this.prescreenedFilter.gender = ds.value;
          ds.overlayVisible = false;
        });
        break;
      case "createdDate":
        this.cleardateCompleted.forEach(ds => {
          this.prescreenedFilter.dateCreated = ds.value;
          ds.overlayVisible = false;
        });
        break;
    }
    if (this.prescreenedFilter.age.includes(0) && !this.prescreenedFilter.age.includes(null))
      this.prescreenedFilter.age.push(null);

    sessionStorage.setItem("UserPreference_PreScreenedLibrary", JSON.stringify(this.prescreenedFilter));
    this.sessionStorePrescreened = sessionStorage.getItem("UserPreference_PreScreenedLibrary");
    this.participantService.getPrescreenedApplicants(this.prescreenedFilter)
      .subscribe(response => {
        this.participantlist = response;
        this.filteredParticipantlist = response;
        this.getFilters(colname, "ok");
      });
  }
  clearPrescreenFilter(colname) {

    if (this.prescreenedFilter.age.includes(0) && !this.prescreenedFilter.age.includes(null))
      this.prescreenedFilter.age.push(null);

    this.participantService.getPrescreenedApplicants(this.prescreenedFilter)
      .subscribe(response => {
        this.participantlist = response;
        this.filteredParticipantlist = response;
        this.getFilters(colname, "clear");
        //this.filterApplicants(colname);
      });
  }

  filterApplicants(filterFor) {
    for (let key in this.filteredParticipantlist) {
      if (this.filteredParticipantlist.hasOwnProperty(key)) {
        this.filteredParticipantlist[key].name = this.filteredParticipantlist[key].lastName + ", " + this.filteredParticipantlist[key].firstName;
        this.filteredParticipantlist[key].gender = this.filteredParticipantlist[key].gender === "M" ? "Male" : this.filteredParticipantlist[key].gender === "F" ? "Female" : this.filteredParticipantlist[key].gender;
        this.filteredParticipantlist[key].age = this.filteredParticipantlist[key].age === null ? 0 : this.filteredParticipantlist[key].age;
        this.filteredParticipantlist[key].createdDate = this.filteredParticipantlist[key].createdDate === null ? "" : this.filteredParticipantlist[key].createdDate;
      }
    }

    if (!this.isClearPrescreenedFilterOn) {
      this.clearStudy.forEach(ds => {
        if (this.isFromSessionStore) {
          ds.value = this.selectedStudyName;
        } else {
          if (this.isFirstVisit || this.sessionStorePrescreened === null) {
            ds.value = this.filteredParticipantlist.map(x => x.studyName)
              .filter((value, index, self) => self.indexOf(value) === index);
          } else {
            ds.value = this.prescreenedFilter.studyNumber;
          }
        }
        ds.updateLabel();
      });
      this.clearage.forEach(ds => {
        if (this.isFromSessionStore) {
          ds.value = this.selectedAge;
        } else {
          if (this.isFirstVisit || this.sessionStorePrescreened === null) {
            ds.value = this.filteredParticipantlist.map(x => x.age)
              .filter((value, index, self) => self.indexOf(value) === index);
          } else {
            ds.value = this.prescreenedFilter.age;
          }
        }
        ds.updateLabel();
      });
      this.cleargender.forEach(ds => {
        if (this.isFromSessionStore) {
          ds.value = this.selectedGender;
        } else {
          if (this.isFirstVisit || this.sessionStorePrescreened === null) {
            ds.value = this.gender.map(x => x.value);
          } else {
            ds.value = this.prescreenedFilter.gender;
          }
        }
        ds.updateLabel();
      });
      this.cleardateCompleted.forEach(ds => {
        if (this.isFromSessionStore) {
          ds.value = this.selectedDateCompleted;
        } else {
          if (this.isFirstVisit || this.sessionStorePrescreened === null) {
            ds.value = this.filteredParticipantlist.map(x => (this.datePipe.transform(x.createdDate, 'dd MMM yyyy')))
              .filter((value, index, self) => self.indexOf(value) === index);
          } else {
            ds.value = this.prescreenedFilter.dateCreated;
          }
        }
        ds.updateLabel();
      });
    }
    this.isFirstVisit = false;
    this.isClearPrescreenedFilterOn = false;
  }
  paginate(event) {
    this.scrollTop.nativeElement.focus();
  }
  exportStudyData(studyDetails) {
    this.participantService.getParticipantList(studyDetails.studyId).subscribe(response => { 
    if(response.length !== 0)
    {
      response.forEach(par => {
        const participantDetail = {
          'First Name': par.firstName,
          'Last Name': par.lastName,
          'Email Id': par.emailId,
          'Phone': par.phone,
          'DOB': par.dob,
          'Gender at Birth': par.gender === 'M' ? 'Male' : 'Female',
          'Height (FT/Inches)': par.height,
          'Age': par.age,
          'When to Call': par.WhentoCall,
          'AttemptDate': par.createdDate,
          'User Status': par.status,
          'Is Admin': par.adminid != null ? 'Yes' : 'No',
          'AdminID': par.adminid,
          'BMI': par.bmi,
          'Attempts': par.attemptCount,
          'Latest attempt': par.latestAttempt
        };
        this.participantListForExport.push(participantDetail);
      });
      setTimeout(() => {
        this.studyService.getQuestionsStudy("PreScreener", studyDetails.studyId).subscribe(res => {
          this.questionTextStudy = res.map(ques => ques.questionText);
          this.questionIdList = res.map(ques => ques.questionId);
          this.participantIdList = response.map(participant => participant.participantId).join(',');          
          this.exportExcel.exportStudyAsExcelFile(this.participantListForExport, "Study Details", this.questionTextStudy, studyDetails);
        });
      }, 20);
    } else {
      alert('No Participant found for this Study.')
    }
  });
  this.participantListForExport = [];
  }
}
