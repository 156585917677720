// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableGridWrap.quesTableWrap .ui-widget .ui-table-wrapper {
  margin: 0;
  clear: both;
  float: left;
  width: 100%;
}

.tableGridWrap.quesTableWrap p-paginator {
  clear: both;
  float: left;
  width: 100%;
}

.questionLibSearch tbody {
  font-size: 13px;
}

.tableGridWrap.quesTableWrap .questionLibSearch .question_Text {
  width: 60%;
}

.questionName {
  color: #5c5c5c;
}

.tagName,
.studyNameList,
.questionName {
  word-break: break-all;
}

.bttn {
  padding: 7px 8px;
  border-radius: 26px;
}

div[name="delete-question"] {
  text-align-last: end;
  width: 21%;
}
button[name=saveBtn],
button[name=editBtn],
button[name=cancelBtn] {
  background: transparent !important;
  top: 7pt;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0 1rem;
}

.questSearch {
  width:auto; 
  flex-grow: 1; 
  margin-top: -10px; 
  padding-left: 40px;
}

::ng-deep p-multiselect ul,
::ng-deep p-multiselect ol {
  padding-left: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/question-library/question-library.component.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,WAAW;EACX,WAAW;EACX,WAAW;AACb;;AAEA;EACE,WAAW;EACX,WAAW;EACX,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;;;EAGE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,UAAU;AACZ;AACA;;;EAGE,kCAAkC;EAClC,QAAQ;AACV;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;;EAEE,0BAA0B;AAC5B","sourcesContent":[".tableGridWrap.quesTableWrap .ui-widget .ui-table-wrapper {\r\n  margin: 0;\r\n  clear: both;\r\n  float: left;\r\n  width: 100%;\r\n}\r\n\r\n.tableGridWrap.quesTableWrap p-paginator {\r\n  clear: both;\r\n  float: left;\r\n  width: 100%;\r\n}\r\n\r\n.questionLibSearch tbody {\r\n  font-size: 13px;\r\n}\r\n\r\n.tableGridWrap.quesTableWrap .questionLibSearch .question_Text {\r\n  width: 60%;\r\n}\r\n\r\n.questionName {\r\n  color: #5c5c5c;\r\n}\r\n\r\n.tagName,\r\n.studyNameList,\r\n.questionName {\r\n  word-break: break-all;\r\n}\r\n\r\n.bttn {\r\n  padding: 7px 8px;\r\n  border-radius: 26px;\r\n}\r\n\r\ndiv[name=\"delete-question\"] {\r\n  text-align-last: end;\r\n  width: 21%;\r\n}\r\nbutton[name=saveBtn],\r\nbutton[name=editBtn],\r\nbutton[name=cancelBtn] {\r\n  background: transparent !important;\r\n  top: 7pt;\r\n}\r\n\r\n.p-datatable .p-datatable-tbody > tr > td {\r\n  padding: 0 1rem;\r\n}\r\n\r\n.questSearch {\r\n  width:auto; \r\n  flex-grow: 1; \r\n  margin-top: -10px; \r\n  padding-left: 40px;\r\n}\r\n\r\n::ng-deep p-multiselect ul,\r\n::ng-deep p-multiselect ol {\r\n  padding-left: 0 !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
