import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminHomeComponent } from './Study/admin_home/admin_home.component';
import { AdminStudyDetailComponent } from './Study/admin_study_detail/admin_study_detail.component';
import { AdminPrescreenerComponent } from './pre-screening-questions/admin_prescreener/admin_prescreener.component';
import { AdminParticipantListComponent } from './admin-participant-list/admin-participant-list.component';
import { AdminParticipantAnswersComponent } from './admin-participant-answers/admin-participant-answers.component';
import { AdminPreScreenerListComponent } from './pre-screening-questions/admin_prescreener_list.component';
import { StudyListResolverService } from 'src/services/study-list-resolver.service';
import { AdminNotFoundComponent } from './admin-not-found/admin-not-found.component';
import { QuestionLibraryComponent } from './question-library/question-library.component';
import { CreatePrescreeningQuestionComponent } from './create-prescreening-question/create-prescreening-question.component';
import { CanDeactivateGuard } from 'src/services/can-deactivate.guard';
import { AdminDemographicsComponent } from './admin_demographic_list/admin_demographic_list.component';

const routes: Routes = [
  {
    path: '', component: AdminHomeComponent, resolve: { studyList: StudyListResolverService }
  },
  {
    path: 'studydetail/:id', component: AdminStudyDetailComponent,canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'studydetail', component: AdminStudyDetailComponent, canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'addnewpre/:studyId', component: AdminPrescreenerComponent
  },
  {
    path: 'addnewpre/:studyId/:questionId', component: AdminPrescreenerComponent
  },
  {
    path: 'Prescreener', component: AdminPreScreenerListComponent
  },
  {
    path: 'QuestionLibrary', component: QuestionLibraryComponent
  },
  {
    path: 'addnewquestions/:studyId', component: CreatePrescreeningQuestionComponent
  },
  {
    path: 'addnewquestions', component: CreatePrescreeningQuestionComponent
  },
  {
    path: 'addnewpre', component: AdminPrescreenerComponent
  },
  {
    path: 'Participants', component: AdminParticipantListComponent
  },
  // {
  //   path: 'admin-participant-answers/:pid/:sid/:qcat', component: AdminParticipantAnswersComponent
  // },
  {
    path: 'admin-participant-answers/:pid/:sid', component: AdminParticipantAnswersComponent
  },
  {
    path: 'notfound', component: AdminNotFoundComponent
  },
  {
    path: '**', component: AdminNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
