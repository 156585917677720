import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root'
})

export class NavigationTrackingService {
    constructor() { }
    private visitedPages: Set<string> = new Set();

    hasVisited(route: string): boolean {
        return this.visitedPages.has(route)
    }

    markAsVisited(route: string): void {
        this.visitedPages.add(route)
    }
}
