import { Injectable } from '@angular/core';
import { CanActivate, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
 
@Injectable({
  providedIn: 'root',
})
export class FirstNavigationGuard implements CanActivate {
    currentUrl = '';
  constructor(private router: Router) {
    this.listenToRouterEvents();
  }

  listenToRouterEvents() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        console.log('Navigating to:', event.url);
        this.currentUrl = event.url;
      });
  }
  
 
  canActivate(): boolean {
    if(this.currentUrl === '') {
        sessionStorage.removeItem('isFirstNavigationToQuestionLibrary');
        sessionStorage.removeItem('isFirstNavigationToApplicants');
        sessionStorage.setItem('adminRefreshed', 'true');
        sessionStorage.setItem('isFirstNavigationToStudies', 'true');
    }
    const isFirstNavigationToStudies = !sessionStorage.getItem('isFirstNavigationToStudies') && this.currentUrl === '/adminhome';
    const isFirstNavigationToQuestionLibrary = !sessionStorage.getItem('isFirstNavigationToQuestionLibrary') && this.currentUrl === '/adminhome/QuestionLibrary';
    const isFirstNavigationToApplicants = !sessionStorage.getItem('isFirstNavigationToApplicants') && this.currentUrl === '/adminhome/Participants';
 
    if(isFirstNavigationToStudies) {
        sessionStorage.setItem('isFirstNavigationToStudies', 'true');
    }
    if(isFirstNavigationToQuestionLibrary) {
        sessionStorage.setItem('isFirstNavigationToQuestionLibrary', 'true');
    }
    if(isFirstNavigationToApplicants) {
        sessionStorage.setItem('isFirstNavigationToApplicants', 'true');
    }
    return true;
  }
}