export class participantDetails {
  public participantId: number;
  public participantResultId: number;
  public firstName: string;
  public lastName: string;
  public emailId: string;
  public phone: string;
  public dob: any;
  public gender: string;
  public bmi: string;
  public weight: string;
  public when_To_Call: string;
  public lastupdated: any;
  public studyId: number;
  public studyName: string;
  public status: string;
  public Dynamic_Options: string;
  public AdminId: string;
 
}
[{ "questionText": "Is that 1 st ?", "answer1": null, "answer2": "yes", "answer3": null }, { "questionText": "Is that 2nd ?", "answer1": null, "answer2": "Yes", "answer3": null }, { "questionText": "Is that 3rd ?", "answer1": null, "answer2": "Yes", "answer3": null }, { "questionText": "Is that 4th ?", "answer1": null, "answer2": "yes", "answer3": null }, { "questionText": "Is that 5th ?", "answer1": null, "answer2": "Yes", "answer3": null }, { "questionText": "Is that 6th ?", "answer1": null, "answer2": "yes", "answer3": null }, { "questionText": "Is that 7th ?", "answer1": null, "answer2": "yes", "answer3": null }, { "questionText": "Is that 8th ?", "answer1": null, "answer2": "Yes", "answer3": null }, { "questionText": "Is that 9th ?", "answer1": null, "answer2": "Yes", "answer3": null }, { "questionText": "Is that 10th ?", "answer1": null, "answer2": "yes", "answer3": null }, { "questionText": "Qualification Status", "answer1": "Disqualified", "answer2": "Qualified", "answer3": null }]

export class participantAnswers {
  public questionText: string;
  public answer1: string;
  public answer2: string;
  public answer3: string;
}
export class participantDetailsExport {
  public Applicant_Name?: string;
  public Email_Address?: string;
  public Contact_number?: string;
  public Preferred_Contact_time?: string;
  public Study_Number?: string;
  public Status?: string;
  public Birthdate?: string;
  public Gender?: string;
  public BMI?: string;
  public Weight?: string;
  public Last_updated?: string;

}
export class ParticipantDynamicValuesModel {
  public D_Value: string;
  public D_Option: string;
}
