import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

  constructor() { }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  exportStudyAsExcelFile(json: any[], excelFileName: string, studyDetails: any, studyStatus: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(studyDetails.studyName + ' Study Details');

    worksheet.getCell('A1').value = studyDetails.studyName + '_' + studyStatus;
    worksheet.getCell('A1').font = { bold: true };

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(1).alignment = { wrapText: true };

    const headers = Object.keys(json[0]);
    headers.forEach((header, index) => {
      const cell = worksheet.getCell(2, index + 1);
      cell.value = header;
      cell.font = { bold: true };
      cell.alignment = { wrapText: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD2B48C' },
      };
    });

    const startRow = 3;
    const startCol = 1;

    json.forEach((row, rowIndex) => {
      Object.values(row).forEach((value, colIndex) => {
        const cell = worksheet.getCell(rowIndex + startRow, colIndex + startCol);
        if(typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean' || value instanceof Date) {
          cell.value = value as ExcelJS.CellValue;
        } else {
          cell.value = String(value);
        }
        cell.alignment = { wrapText: true };
      });
    });

    headers.forEach((header, index) => {
      worksheet.getColumn(index + startCol).width = 20;
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      this.saveAsStudyExcelFile(buffer, studyDetails.studyName + ' ' + excelFileName);
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  private saveAsStudyExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    FileSaver.saveAs(data, fileName + '.xlsx');
  }
}
