import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { questionModel } from 'src/models/question.model';
import { AdminService } from 'src/services/admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/services/config.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { allQuestionsModel } from '../../model/prescreeningquetion';
import { questionstudyModel, studyQuestionModel } from 'src/models/questionstudy.model';
import { ExcelExportService } from 'src/app/service/excel-export.service';
import { sessionData } from 'src/models/data.model';
import { DataService } from 'src/services/data.service';

@Component({
  selector: 'app-adminprescreenerlist',
  templateUrl: './admin_prescreener_list.component.html',
  styleUrls: ['./admin_prescreener_list.component.css']
})

export class AdminPreScreenerListComponent implements OnInit {
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.adminQuestModel, event.previousIndex, event.currentIndex);
    this.dataService.onUnload = false;
  }

  public qModel: questionModel[] = [];
  public admQuesModel: allQuestionsModel[];
  public adminQuestModel: questionModel[] = [];
  public beforeEditQuestModel: questionModel[] = [];

  @Input() studyId: number = 0;
  @Input() questionFlag: string;
  @Output() messageToParent = new EventEmitter<string>();
  studyid: number;
  newQuestionOrderIndex: number = 0;
  _studyid;
  public exportExcelList: any[];

  public questionmodel: questionModel;
  public qsModel: questionstudyModel;
  public qsModelAll: questionstudyModel[] = [];
  public sessionData: sessionData;
  public showProgressBar: boolean = true;
  constructor(private admin: AdminService, private router: Router, private configService: ConfigService, private activateRoute: ActivatedRoute, private exportExcel: ExcelExportService, private dataService: DataService) {
    this.dataService.onUnload = true;
    this.questionmodel = new questionModel();
    this.qsModel = new questionstudyModel();
    this.qsModel.question = new questionModel();
    this.qsModel.studyQuestion = new studyQuestionModel();
    this.qsModel.answer = [];
  }

  userId: string;
  showCreateNewQuest: boolean = false;
  isLoading = false;
  ngOnInit() {
    this.newQuestionOrderIndex = 0;
    this.showCreateNewQuest = !this.questionFlag;
    this.getPreScreenerQuestions();
    this.activateRoute.paramMap.subscribe(params => {
      this.studyid = parseInt(params.get('id'));
    });
    this.getAdminQuestions();
  }

  getQuestionIndex() {
    this.newQuestionOrderIndex;
  }

  getqsModel() {
    this.qsModelAll = [];

    for (let i = 0; i < this.adminQuestModel.length; i++) {
      this.qsModel = new questionstudyModel();
      this.qsModel.question = new questionModel();
      this.qsModel.studyQuestion = new studyQuestionModel();
      this.qsModel.answer = [];

      this.qsModel.question.questionText = this.adminQuestModel[i].questionText.trim();
      this.qsModel.question.toolTip = "NA";
      this.qsModel.question.isActive = true;
      if (this.adminQuestModel[i].includeFreeText && this.adminQuestModel[i].includePredefined) {
        this.qsModel.question.answerType = "Radio-Text";
      } else if (this.adminQuestModel[i].includeFreeText) {
        this.qsModel.question.answerType = "Text";
      } else if (this.adminQuestModel[i].includePredefined) {
        this.qsModel.question.answerType = "Radio";
      }
      this.qsModel.question.questionCategory = "PreScreener";
      this.qsModel.question.includeFreeText = this.adminQuestModel[i].includeFreeText;
      this.qsModel.question.includePredefined = this.adminQuestModel[i].includePredefined;
      this.qsModel.question.studyId = this.adminQuestModel[i].studyId;
      this.qsModel.question.questionId = this.adminQuestModel[i].questionId;
      this.qsModel.question.index = i;
      this.qsModel.question.questionOrder = i + 1;
      this.qsModel.studyQuestion.includeFreeText = this.adminQuestModel[i].includeFreeText;
      this.qsModel.studyQuestion.includePredefined = this.adminQuestModel[i].includePredefined;
      this.qsModel.studyQuestion.isPrescreener = true;
      this.qsModel.studyQuestion.isActive = true;
      this.qsModel.studyQuestion.studyId = this.adminQuestModel[i].studyId;
      this.qsModel.studyQuestion.questionOrder = i + 1;

      if (this.beforeEditQuestModel.filter(x => x.questionText == this.adminQuestModel[i].questionText.trim() && x.questionId == this.adminQuestModel[i].questionId).length == 0) {
        let quesId = this.qsModel.question.questionId;
        this.qsModel.question.removequestionId = quesId;
        this.qsModel.question.questionId = null;
      }

      for (let cntrl of this.adminQuestModel[i].answers) {
        if (cntrl.answerText.trim() != "" && cntrl.answerText != undefined)
          this.qsModel.answer.push({
            answerId: cntrl.answerId,
            answerText: cntrl.answerText.trim(),
            isCorrect: cntrl.correct_Answer,
            isActive: true
          });
      }
      this.qsModelAll.push(this.qsModel);
    }
    this.isLoading = true;
    this.admin.updateAllQuestionsForAdmin(this.qsModelAll)
      .subscribe(res => {
        this.isLoading = false;
        if (res != "") {
          let alertstr = res.split('|').join(', ');
          alertstr = 'Question(s) "' + alertstr + '" already exists';
          this.dataService.onUnload = false;
          alert(alertstr);
        }
        else {
          alert('Question Updated Successfully');
          this.dataService.onUnload = true;
          this.getAdminQuestions();
        }
      });
  }

  saveChanges() {
    if (this.adminQuestModel.some(question => !question.includeFreeText && !question.includePredefined)) {
      alert('Atleast one question option needs to be chosen!');
      return false;
    }

    for (let i = 0; i < this.adminQuestModel.length; i++) {
      if (this.adminQuestModel[i].questionText.trim() == "") {
        alert("Question text cannot be empty!");
        return false;
      }

      let flag = true;
      let disqualifyflag = false;
      for (let cntrl of this.adminQuestModel[i].answers) {
        if (cntrl.answerText.trim() == "" || cntrl.answerText == undefined) {
          flag = false;
          break;
        }
        if (cntrl.correct_Answer == true) {
          disqualifyflag = true;
        }
      }
      if (!flag) {
        alert("Answers cannot be empty for '" + this.adminQuestModel[i].questionText.trim() + "'")
        return false;
      }
      if (!disqualifyflag) {
        alert("Atleast one option should be Qualify for '" + this.adminQuestModel[i].questionText.trim() + "'")
        return false;
      }
    }
    //return false;
    this.getqsModel();

  }

  ngAfterViewInit() {
  }
  ngOnDestroy() {
  }
  addAnswer(index: number, answerRow: number) {

    this.adminQuestModel[index].answers.push({
      answerId: null,
      answerText: "",
      correct_Answer: true
      //isCorrect: true,
      //isActive: true
    });
    this.dataService.onUnload = false;
  }
  removeAnswer(rowIndex: number, answerRow: number) {
    this.adminQuestModel[rowIndex].answers.splice(answerRow, 1);
    this.dataService.onUnload = false;
  }

  getAdminQuestions() {
    if (this.studyid != null && this.studyid != undefined && this.studyid != 0 && this.studyid.toString() != 'NaN') {
      this.admin.getQuestionsForAdmin(this.studyid, 'PreScreener')
        .subscribe(res => {
          this.showProgressBar = false;
          this.adminQuestModel = res;
          this.beforeEditQuestModel = this.adminQuestModel.map(x => Object.assign({}, x));
        });
    }
  }
  getPreScreenerQuestions() {
    if (this.studyId <= 0) {
      this.showProgressBar = false;
    }
    else {
      this.admin.getQuestionsStudy("PreScreener", this.studyId)
        .subscribe(res => {
          this.qModel = res;
          this.showProgressBar = false;
        });
    }
  }
  deletePreScreenerQuestions(qId: number, sId: number) {
    if (confirm("Are you sure you want to delete the question?")) {
      if (!isNaN(sId) && sId > 0) {
        this.admin.deleteQS(qId, sId)
          .subscribe(res => {
            alert("Question Deleted Successfully")
            this.router.navigateByUrl('adminhome');
          });
      }
      else {
        // if (confirm("Are you sure to delete the question?")){}
        this.admin.deleteQuestion(qId)
          .subscribe(res => {
            alert("Question Deleted Successfully")
            this.router.navigateByUrl('adminhome');
          });
      }
    }
  }
  onIncludeFreeText(event, questionId) {
    let updateItem = this.adminQuestModel.filter(x => x.questionId == questionId)[0];
    let index = this.adminQuestModel.indexOf(updateItem);

    this.adminQuestModel[index].includeFreeText = event.target.checked;
    this.dataService.onUnload = false;
  }

  onIncludePredefined(event, questionId) {
    let updateItem = this.adminQuestModel.filter(x => x.questionId == questionId)[0];
    let index = this.adminQuestModel.indexOf(updateItem);
    this.adminQuestModel[index].includePredefined = event.target.checked;
    this.dataService.onUnload = false;
  }
  removeQuestions(questionId) {
    this.admin.deleteQS(questionId, this.studyId)
      .subscribe(r => {
        this.adminQuestModel = this.adminQuestModel.filter(x => x.questionId != questionId);
        this.beforeEditQuestModel = this.beforeEditQuestModel.filter(x => x.questionId != questionId);
      });
  }
  exportAsXLSX() {
    this.admin.getStudyDetails(this.studyid)
      .subscribe(response => {
        this.admin.getQuestionsAnswerExport(this.studyid.toString()).subscribe(result => {
          this.exportExcelList = result;
          let fileName = "Study " + response.studyName + "_Questions";

          this.exportExcel.exportAsExcelFile(this.exportExcelList, fileName);
        });
      }, error => {
      });
  }

  addQuestionAtIndex(index) {

    this.addNewQuestion();
    this.newQuestionOrderIndex = index;
  }

  addNewQuestion() {
    this.newQuestionOrderIndex = 0;
    if (this.studyid == null || this.studyid == undefined || this.studyid == 0 || this.studyid.toString() == 'NaN') {
      this.sessionData = this.dataService.getData();
      this.studyid = this.sessionData.studyId;
    }

    if (this.studyid == null || this.studyid == undefined || this.studyid == 0 || this.studyid.toString() == 'NaN') {
      alert("Study not created. Study details required.");
      return false;
    }
    this.showCreateNewQuest = true;
  }


  getResponseFromChild(response: string) {

    this.messageToParent.emit("true");
    this.getAdminQuestions();
    // moveItemInArray(this.adminQuestModel, this.adminQuestModel.length, this.newQuestionOrderIndex + 1);
    this.dataService.onUnload = false;
    this.showCreateNewQuest = false;

  }
  onQuestionChange() {
    this.dataService.onUnload = false;
  }
}
