import { Component, OnInit, Input } from '@angular/core';
import { stratificationModel, questionStratification, StraAdditional } from 'src/app/model/demographics.model';
import { AdminService } from 'src/services/admin.service';
import { questionModel, bindingModel } from 'src/models/question.model';
import { questionstudyModel, studyQuestionModel } from 'src/models/questionstudy.model';
import { Router } from '@angular/router';
import { ConfigService } from 'src/services/config.service';
import { RoundPipe } from 'src/app/admin/round.pipe';
import { DataService } from 'src/services/data.service';
import { sessionData } from 'src/models/data.model';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-admindemographics',
  templateUrl: './admin_demographic_list.component.html',
  styleUrls: ['./admin_demographic_list.component.css']
})
export class AdminDemographicsComponent implements OnInit {

  isBetween: boolean;
  @Input() isEditable: string;
  @Input() studyId: number;
  public qModel: questionModel[];
  public qsModel: questionstudyModel;
  public qsDemographicsModel: any[];
  public qBind: bindingModel[] = [];
  public stratificationModel: stratificationModel[] = [];
  public DynamicModel: StraAdditional[] = [];
  public strmodel: stratificationModel[] = [];
  public objstratificationModel: stratificationModel = new stratificationModel();
  public stratificationNo: number = 0;
  public ageId: number;
  public weightId: number;
  public bmiId: number;
  public genderId: number;
  public testId: number;
  public ss: number = 0;
  public FromValueValidate: number;
  public ToValueValidate: number;
  public IsValid: boolean = true;
  public IsValidName: string;
  public IsValidStrNo: number;
  public CheckItem: StraAdditional = new StraAdditional;
  public itemname: string;
  public ageValidationFlag: boolean = true;
  public weightValidationFlag: boolean = true;
  public bmiValidationFlag: boolean = true;
  public questionLists: questionStratification[];
  public sessionData: sessionData;
  public qsModelArray: questionstudyModel[] = [];
  public toppingList: string[];
  customStratificationEnabled = false;
  form: FormGroup;
  selectedOption = '1';
  isLoading = false;
  isDeleting = false;
  isDeletingStrat = false;

  public get questionCategory(): string {
    return "Demographics";
  }
  printObj: Object;

  constructor(private admin: AdminService, private router: Router, private configService: ConfigService, private dataService: DataService) {
    this.qsModel = new questionstudyModel();
    this.qsModelArray = [];
    this.qsModel.question = new questionModel();
    this.qsModel.studyQuestion = new studyQuestionModel();
    this.qsModel.answer = [];
    this.CheckItem = null;
    this.form = new FormGroup({
      selectedOption: new FormControl('1')
    });
  }

  filterPipe = new RoundPipe();
  userId: string;
  ngOnInit() {
    this.getMasterValues();
    this.getDemographicsQuestions();
    this.getDemoGraphicDynamicValues();
  }
  ddlOperatorChange(_qModel: any): void {
  }



  getMasterValues() {
    this.admin.getMasterValuesforCombo().subscribe(res => {
      this.toppingList = res;
    });

  }

  getDemographicsQuestions() {
    this.admin.getStratificationQuestions().subscribe(res => {
      this.questionLists = res;
      this.ageId = this.questionLists.filter(x => x.questionText == "Age")[0].questionId;
      this.weightId = this.questionLists.filter(x => x.questionText == "Weight")[0].questionId;
      this.genderId = this.questionLists.filter(x => x.questionText == "Gender")[0].questionId;
      this.bmiId = this.questionLists.filter(x => x.questionText == "BMI")[0].questionId;
      this.getQSDemographics();
    });
  }

  getQSDemographics() {
    this.admin.getQSDemographics(isNaN(this.studyId) ? 0 : this.studyId)
    .subscribe(res => {
      res.forEach(b => {
        b.groupId = b.groupId == null ? 1 : b.groupId;
        if (b.answer1Id > 0) {
          if (b.questionText != 'Gender') {
            b.answer1 = this.filterPipe.transform(parseFloat(b.answer1)).toString();
            b.answer2 = this.filterPipe.transform(parseFloat(b.answer2)).toString();
          }
          b.includeCondition = true;
        }
        else b.answerType = "Text";
      });
      this.qBind = this.refactorGroupIds(res.sort((a, b) => a.groupId - b.groupId));
      let groupId = Array.from(this.qBind.reduce((m, t) => m.set(t.groupId, t), new Map()).values());
      if (groupId.length == 0) {
        this.stratificationNo = this.stratificationNo + 1;
        this.stratificationModel.push(
          {
            sno: this.stratificationNo, studyId: null, questionText: null,
            ageQuestionId: null, ageAnswerType: null, ageFrom: null, ageTo: null, ageFromId: null, ageToId: null,
            weightQuestionId: null, weightAnswerType: null, weightFrom: null, weightTo: null, weightFromId: null, weightToId: null,
            bmiQuestionId: null, bmiAnswerType: null, bmiFrom: null, bmiTo: null, bmiFromId: null, bmiToId: null,
            genderQuestionId: null, genderAnswerType: null, male: false, female: false, genderId: null, stGroup: null,
            ageValidate: true, weightValidate: true, bmiValidate: true
          }
        );
      }
      for (let k = 0; k < groupId.length; k++) {
        this.stratificationNo = this.stratificationNo + 1;
        this.stratificationModel.push(
          {
            sno: this.stratificationNo, studyId: null, questionText: null,
            ageQuestionId: null, ageAnswerType: null, ageFrom: null, ageTo: null, ageFromId: null, ageToId: null,
            weightQuestionId: null, weightAnswerType: null, weightFrom: null, weightTo: null, weightFromId: null, weightToId: null,
            bmiQuestionId: null, bmiAnswerType: null, bmiFrom: null, bmiTo: null, bmiFromId: null, bmiToId: null,
            genderQuestionId: null, genderAnswerType: null, male: false, female: false, genderId: null, stGroup: null,
            ageValidate: true, weightValidate: true, bmiValidate: true
          }
        );

        let stratificationList = this.qBind.filter(x => x.groupId == groupId[k].groupId).map(x => Object.assign({}, x));
        for (let l = 0; l < stratificationList.length; l++) {
          this.stratificationModel[k].questionText = stratificationList[l].questionText;
          this.stratificationModel[k].stGroup = stratificationList[l].groupId;

          stratificationList[l].answer1 = stratificationList[l].answer1 == "-999" ? null : stratificationList[l].answer1;
          stratificationList[l].answer1 = stratificationList[l].answer1 == "999" ? null : stratificationList[l].answer1;
          stratificationList[l].answer2 = stratificationList[l].answer2 == "999" ? null : stratificationList[l].answer2;
          switch (stratificationList[l].questionText) {
            case "Age":

              this.stratificationModel[k].ageQuestionId = stratificationList[l].questionId;
              this.stratificationModel[k].ageAnswerType = stratificationList[l].questionText;
              if (stratificationList[l].answer1 != null && stratificationList[l].answer1.toLowerCase() != 'null') {
                this.stratificationModel[k].ageFrom = parseFloat(stratificationList[l].answer1);
                this.stratificationModel[k].ageFromId = stratificationList[l].answer1Id;

              }
              if (stratificationList[l].answer2 != null && stratificationList[l].answer2.toLowerCase() != 'null') {
                this.stratificationModel[k].ageTo = parseFloat(stratificationList[l].answer2)
                this.stratificationModel[k].ageToId = stratificationList[l].answer2Id;


              }
              break;
            case "Weight":
              this.stratificationModel[k].weightQuestionId = stratificationList[l].questionId;
              this.stratificationModel[k].weightAnswerType = stratificationList[l].questionText;
              if (stratificationList[l].answer1 != null && stratificationList[l].answer1.toLowerCase() != 'null') {
                this.stratificationModel[k].weightFrom = parseFloat(stratificationList[l].answer1)
                this.stratificationModel[k].weightFromId = stratificationList[l].answer1Id;

              }
              if (stratificationList[l].answer2 != null && stratificationList[l].answer2.toLowerCase() != 'null') {
                this.stratificationModel[k].weightTo = parseFloat(stratificationList[l].answer2)
                this.stratificationModel[k].weightFromId = stratificationList[l].answer2Id;


              }
              break;
            case "BMI":
              this.stratificationModel[k].bmiQuestionId = stratificationList[l].questionId;
              this.stratificationModel[k].bmiAnswerType = stratificationList[l].questionText;
              if (stratificationList[l].answer1 != null && stratificationList[l].answer1.toLowerCase() != 'null') {
                this.stratificationModel[k].bmiFrom = parseFloat(stratificationList[l].answer1)
                this.stratificationModel[k].bmiFromId = stratificationList[l].answer1Id;


              }
              if (stratificationList[l].answer2 != null && stratificationList[l].answer2.toLowerCase() != 'null') {
                this.stratificationModel[k].bmiTo = parseFloat(stratificationList[l].answer2)
                this.stratificationModel[k].bmiFromId = stratificationList[l].answer2Id;


              }
              break;
            case "Gender":
              this.stratificationModel[k].genderQuestionId = stratificationList[l].questionId;
              this.stratificationModel[k].genderAnswerType = stratificationList[l].questionText;
              if (stratificationList[l].answer1 != null && stratificationList[l].answer1.toLowerCase() != 'null') {
                if (stratificationList[l].answer1 == "M")
                  this.stratificationModel[k].male = true
                if (stratificationList[l].answer1 == "F")
                  this.stratificationModel[k].female = true
                if (stratificationList[l].answer1 == "B") {
                  this.stratificationModel[k].male = true
                  this.stratificationModel[k].female = true
                }
                this.stratificationModel[k].genderId = stratificationList[l].answer1Id;

              }
              break;
          }
        }
      }
    });
  }

  refactorGroupIds(list: any[]): any[] {
    const groupIdMap: { [key: number]: number } = {};
    let newGroupId = 1;

    list.forEach(item => {
        if (!groupIdMap[item.groupId]) {
            groupIdMap[item.groupId] = newGroupId++;
        }
        item.groupId = groupIdMap[item.groupId];
    });
    return list;
  }

  refactorDynamicGroupNo(list: any[]): any[] {
    const grpNoMap: { [key: number]: number } = {};
    const strNoMap: { [key: number]: number } = {};
    let newGroupId = 1;
    let newStrNo = 1;

    list.forEach(item => {
        if (!grpNoMap[item.grpNo]) {
          grpNoMap[item.grpNo] = newGroupId++;
        }
        if (!strNoMap[item.strno]) {
          strNoMap[item.strno] = newStrNo++;
        }
        item.grpNo = grpNoMap[item.grpNo];
        item.strno = strNoMap[item.strno];
    });
    return list;
  }

  refactorStratificationModel(list: stratificationModel[]): stratificationModel[] {
    const grpNoMap: { [key: number]: number } = {};
    const strNoMap: { [key: number]: number } = {};
    let newGroupId = 1;
    let newStrNo = 1;

    list.forEach(item => {
        if (!grpNoMap[item.stGroup]) {
          grpNoMap[item.stGroup] = newGroupId++;
        }
        if (!strNoMap[item.sno]) {
          strNoMap[item.sno] = newStrNo++;
        }
        item.stGroup = grpNoMap[item.stGroup];
        item.sno = strNoMap[item.sno];
    });
    return list;
  }
  
  saveStratification() {
    this.isLoading = true;
    this.addQuestionStudy();    
  }

  SaveDynamicFields() {
    this.DynamicModel.forEach((v, i) => {
      if (v.grpNo == null) {
        let m = 1;
        this.stratificationModel.forEach((k, l) => {
          if (v.strno == k.sno) { v.grpNo = m; }
          m = m + 1;
        });
      }
      else {
        this.ss = v.grpNo;
      }
      if (String(v.fromValue) == "") {
        v.fromValue = null;
      }
      if (String(v.toValue) == "") {
        v.toValue = null;
      }
    });    
    this.admin.postUpdateDynamicStratification(this.DynamicModel)
    .subscribe(res => {
      this.isLoading = false;
      this.refreshStratification();
      alert('Record saved Successfully');
    });
  }

  addQuestionStudy() {
    let isValid = true;
    this.sessionData = this.dataService.getData();
    if (this.studyId == null) {
      this.studyId = this.sessionData.studyId;
    }
    if (this.studyId.toString() == "" || this.studyId.toString() == 'NaN') {
      this.studyId = this.sessionData.studyId;
    }
    if (this.studyId == undefined) {
      this.isLoading = false;
      alert("Study not created. Study details required.");
      return false;
    }
    if (this.studyId == null) {
      this.isLoading = false;
      alert("Study not created. Study details required.");
      return false;
    }
    if (this.studyId.toString() == "") {
      this.isLoading = false;
      alert("Study not created. Study details required.");
      return false;
    }
    if (this.studyId.toString() == 'NaN') {
      this.isLoading = false;
      alert("Study not created. Study details required.");
      return false;
    }

    this.stratificationModel.forEach((v, i) => {
      if (v.ageValidate == false || v.weightValidate == false || v.bmiValidate == false) {
        isValid = false;
      }
    });

    let genderVal = true;
    this.stratificationModel.forEach((v, i) => {
      if (v.male == false && v.female == false) {
        genderVal = false;
      }
    });
    if (!genderVal) {
      this.isLoading = false;
      alert('Please select gender!');
      return false;
    }
    if (!isValid) {
      this.isLoading = false;
      alert('Invalid answer provided!');
      return false;
    }
    if (!this.IsValid) {
      this.isLoading = false;
      alert('Invalid answer provided!');
      return false;
    }

    let stGroupId = Math.max.apply(Math, this.stratificationModel.map(function (o) { return o.stGroup; }));
    let validationFlag = false;

    this.stratificationModel.forEach((v, i) => {
      if (v.ageFrom != null || v.ageTo != null || v.weightFrom != null || v.weightTo != null || v.bmiFrom != null || v.bmiTo != null || v.male == true || v.female == true) {
        validationFlag = true;
      }
      let grpId = 0;
      if (v.stGroup != null && v.stGroup != 0) {
        grpId = v.stGroup;
      }
      else {
        grpId = stGroupId + 1;
        stGroupId = stGroupId + 1;
      }
      if ((v.ageFrom != null || v.ageTo != null)) {
        this.insertStratification(this.ageId, "Age", "Age", v.ageFromId, v.ageToId, v.ageFrom, v.ageTo, grpId);
      }
      if ((v.weightFrom != null || v.weightTo != null)) {
        this.insertStratification(this.weightId, "Weight", "Weight", v.weightFromId, v.weightToId, v.weightFrom, v.weightTo, grpId)
      }
      if ((v.bmiFrom != null || v.bmiTo != null)) {
        this.insertStratification(this.bmiId, "BMI", "BMI", v.bmiFromId, v.bmiToId, v.bmiFrom, v.bmiTo, grpId)
      }
      if ((v.male == true || v.female == true)) {
        let gender = v.male == true && v.female == true ? 'B' : v.male == true ? 'M' : v.female == true ? 'F' : '';
        this.insertStratification(this.genderId, "Gender", "Gender", v.genderId, 0, gender, 0, grpId)
      }
      if (v.genderId > 0 && v.male == false && v.female == false) {
        this.admin.deleteQuestionStratification(this.genderId, this.studyId, grpId)
          .subscribe(r => { });
      }
    });
    if (!validationFlag) {
      this.isLoading = false;
      alert("No details entered!")
      return false;
    }
    
    this.admin.postUpdateQSgroup(this.qsModelArray)
    .subscribe(res => {
      this.qsModelArray = [];
      this.SaveDynamicFields();
    });    
  }
  insertStratification(questionId, questionText, answerType, answer1Id, answer2Id, answer1, answer2, stGroup) {
    this.qsModel = new questionstudyModel();
    this.qsModel.question = new questionModel();
    this.qsModel.studyQuestion = new studyQuestionModel();
    this.qsModel.answer = [];
    answer1 = answer1 == "" ? null : answer1;
    answer2 = answer2 == "" ? null : answer2;
    if ((answer1Id > 0 && answer1 == null && answer2 == null)) {
      this.admin.deleteQS(questionId, this.studyId)
        .subscribe(r => { });
    }
    else {
      answer1 = answer1 == null ? -999 : answer1;
      answer2 = answer2 == null ? 999 : answer2;
      this.qsModel.question.questionId = questionId;
      this.qsModel.question.questionText = questionText;
      this.qsModel.question.toolTip = "NA";
      this.qsModel.question.questionCategory = this.questionCategory;
      this.qsModel.studyQuestion.studyId = this.studyId;
      this.qsModel.studyQuestion.questionOrder = 0;
      this.qsModel.studyQuestion.isPrescreener = false;
      this.qsModel.studyQuestion.isActive = true;
      this.qsModel.question.answerType = answerType;
      this.qsModel.studyQuestion.stGroup = stGroup;
      if (answer1Id > 0 && answer2Id > 0) {
        this.qsModel.answer.push({
          answerId: answer1Id, answerText: answer1, isCorrect: false, isActive: true
        });
        if (questionText != "Gender") {
          this.qsModel.answer.push({
            answerId: answer2Id, answerText: answer2, isCorrect: false, isActive: true
          });
        }
      }
      else if (answer1Id > 0 && (answer2Id == null || answer2Id == 0)) {
        this.qsModel.answer.push({
          answerId: answer1Id, answerText: answer1, isCorrect: false, isActive: true
        });
        if (questionText != "Gender") {
          this.qsModel.answer.push({
            answerId: null, answerText: answer2, isCorrect: false, isActive: true
          });
        }
      }
      else {
        this.qsModel.answer.push({
          answerId: 0, answerText: answer1, isCorrect: false, isActive: true
        });
        if (questionText != "Gender") {
          this.qsModel.answer.push({
            answerId: 0, answerText: answer2, isCorrect: false, isActive: true
          });
        }
      }
      this.qsModelArray.push(this.qsModel);
    }
  }
  numberOnly(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && charCode != 46 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (parseInt(event.target.value) > 999) {
      return false;
    }
    return true;
  }
  addStratification() {
    this.stratificationNo = this.stratificationNo + 1;    
    this.stratificationModel.push(
      {
        sno: this.stratificationNo, studyId: null, questionText: null,
        ageQuestionId: null, ageAnswerType: null, ageFrom: null, ageTo: null, ageFromId: null, ageToId: null,
        weightQuestionId: null, weightAnswerType: null, weightFrom: null, weightTo: null, weightFromId: null, weightToId: null,
        bmiQuestionId: null, bmiAnswerType: null, bmiFrom: null, bmiTo: null, bmiFromId: null, bmiToId: null,
        genderQuestionId: null, genderAnswerType: null, male: false, female: false, genderId: null, stGroup: null,
        ageValidate: true, weightValidate: true, bmiValidate: true
      }
    );
  }
  getDemoGraphicDynamicValues() {
    this.admin.getDemographicDynamicValues(isNaN(this.studyId) ? 0 : this.studyId)
      .subscribe(res => {
        this.DynamicModel = this.refactorDynamicGroupNo(res);
      });
  }

  OnComboSelect(rowNumber, stno, topping, GrpNo) {
    let customStratValue = '2';
    if (topping.value != 1) {
      this.CheckItem = this.DynamicModel.find((x) => (x.PrName == topping.value && x.strno == stno)) || null

      if (this.CheckItem == null) {
        this.DynamicModel.push({
          strno: stno,
          studyId: this.studyId,
          PrName: topping.value,
          fromValue: null,
          toValue: null,
          questionId: null,
          answerFromId: null,
          answerToId: null,
          grpNo: GrpNo,
          customStrat: topping.value == 2
        });
      }
      if (this.DynamicModel.filter(dyn => dyn.customStrat).length) {
        customStratValue = this.DynamicModel.filter(dyn => dyn.customStrat)[0].PrName;
      }
      this.DynamicModel = this.DynamicModel.sort((a, b) => {
        if (a.PrName == customStratValue) return 1;
        if (b.PrName == customStratValue) return -1;
        return 0;
      });
    }
    this.form.get('selectedOption').setValue('1');
  }

  checkMax(strno, prname) {
    let DyMdl = this.DynamicModel.find((x) => (x.PrName == prname && x.strno == strno)) || null
    this.FromValueValidate = DyMdl.fromValue;
    this.ToValueValidate = DyMdl.toValue;
    if (this.FromValueValidate != null && this.ToValueValidate != null) {
      if (Number(this.FromValueValidate) > Number(this.ToValueValidate)) {
        this.IsValid = false;
        this.IsValidName = DyMdl.PrName;
        this.IsValidStrNo = strno;
      }
      else {
        this.IsValid = true;
        this.IsValidName = DyMdl.PrName;
        this.IsValidStrNo = strno;
      }
    }
    else {
      this.IsValid = true;
      this.IsValidName = DyMdl.PrName;
      this.IsValidStrNo = strno;
    }
  }
  removeAdditionalInfo(stno, itemname) {
    this.isDeleting = true;
    let GrpId = this.DynamicModel.find((x) => (x.PrName == itemname && x.strno == stno)) || null;
    this.DynamicModel.splice(this.DynamicModel.findIndex((x) => (x.PrName === itemname && x.strno === stno)), 1);    
    this.admin.deleteDynamicStratification(isNaN(this.studyId) ? 0 : this.studyId, GrpId.grpNo, GrpId.PrName)
      .subscribe(r => {
        this.isDeleting = false; 
        this.form.get('selectedOption').setValue('1'); 
      });
  }
  removeStratification(rowNumber, sno) {
    this.isDeletingStrat = true;    
    let deleteStratification = this.stratificationModel.filter(x => x.sno == sno)[0];
    if ((this.DynamicModel.find((x) => (x.strno == sno)) || null) != null) {
      this.DynamicModel = this.DynamicModel.filter(x => x.strno !== sno);
      this.DynamicModel = this.refactorDynamicGroupNo(this.DynamicModel);
    }
    this.admin.deleteStratification(isNaN(this.studyId) ? 0 : this.studyId, deleteStratification.stGroup)
      .subscribe(r => {
        this.isDeletingStrat = false; 
        this.stratificationModel = this.stratificationModel.filter(x => x.sno != sno);
        this.stratificationModel = this.refactorStratificationModel(this.stratificationModel);
        this.stratificationNo = this.stratificationModel.reduce((max, item) => {
            return item.sno > max ? item.sno : max;
        }, 0);
        if (!this.stratificationModel.length) {
          this.stratificationNo = 1;
          this.stratificationModel.push(
            {
              sno: this.stratificationNo, studyId: null, questionText: null,
              ageQuestionId: null, ageAnswerType: null, ageFrom: null, ageTo: null, ageFromId: null, ageToId: null,
              weightQuestionId: null, weightAnswerType: null, weightFrom: null, weightTo: null, weightFromId: null, weightToId: null,
              bmiQuestionId: null, bmiAnswerType: null, bmiFrom: null, bmiTo: null, bmiFromId: null, bmiToId: null,
              genderQuestionId: null, genderAnswerType: null, male: false, female: false, genderId: null, stGroup: null,
              ageValidate: true, weightValidate: true, bmiValidate: true
            }
          );
        }
    });
  }
  checkAgeMax(sno) {
    let tempStrat_Model = this.stratificationModel.filter(x => x.sno == sno)[0];
    let itemIndex = this.stratificationModel.findIndex(item => item.sno == sno);
    if (tempStrat_Model.ageFrom != null && tempStrat_Model.ageTo != null) {
      if (tempStrat_Model.ageFrom.toString() != "" && tempStrat_Model.ageTo.toString() != "") {
        if (Number(tempStrat_Model.ageFrom) >= Number(tempStrat_Model.ageTo)) {
          this.stratificationModel[itemIndex].ageValidate = false;
          this.ageValidationFlag = false;
        } else {
          this.stratificationModel[itemIndex].ageValidate = true;
          this.ageValidationFlag = true;
        }
      } else {
        this.stratificationModel[itemIndex].ageValidate = true;
        this.ageValidationFlag = true;
      }
    } else {
      this.stratificationModel[itemIndex].ageValidate = true;
      this.ageValidationFlag = true;
    }
  }
  checkWeightMax(sno) {
    let tempStrat_Model = this.stratificationModel.filter(x => x.sno == sno)[0];
    let itemIndex = this.stratificationModel.findIndex(item => item.sno == sno);
    if (tempStrat_Model.weightFrom != null && tempStrat_Model.weightTo != null) {
      if (tempStrat_Model.weightFrom.toString() != "" && tempStrat_Model.weightTo.toString() != "") {
        if (Number(tempStrat_Model.weightFrom) >= Number(tempStrat_Model.weightTo)) {
          this.stratificationModel[itemIndex].weightValidate = false;
          this.weightValidationFlag = false;
        } else {
          this.stratificationModel[itemIndex].weightValidate = true;
          this.weightValidationFlag = true;
        }
      }
      else {
        this.stratificationModel[itemIndex].weightValidate = true;
        this.weightValidationFlag = true;
      }
    }
    else {
      this.stratificationModel[itemIndex].weightValidate = true;
      this.weightValidationFlag = true;
    }
  }
  checkBMIMax(sno) {
    let tempStrat_Model = this.stratificationModel.filter(x => x.sno == sno)[0];
    let itemIndex = this.stratificationModel.findIndex(item => item.sno == sno);

    if (tempStrat_Model.bmiFrom != null && tempStrat_Model.bmiTo != null) {
      if (tempStrat_Model.bmiFrom.toString() != "" && tempStrat_Model.bmiTo.toString() != "") {
        if (Number(tempStrat_Model.bmiFrom) >= Number(tempStrat_Model.bmiTo)) {
          this.stratificationModel[itemIndex].bmiValidate = false;
          this.bmiValidationFlag = false;
        } else {
          this.stratificationModel[itemIndex].bmiValidate = true;
          this.bmiValidationFlag = true;
        }
      } else {
        this.stratificationModel[itemIndex].bmiValidate = true;
        this.bmiValidationFlag = true;
      }
    } else {
      this.stratificationModel[itemIndex].bmiValidate = true;
      this.bmiValidationFlag = true;
    }
  }

  setCustomStratification(strat) {
    this.DynamicModel.forEach(obj => {
      if (obj.customStrat) {
        obj.PrName = strat;
      }
    });
  }

  refreshStratification() {
    this.stratificationModel = [];
    this.DynamicModel = [];
    this.stratificationNo = 0;
    this.getMasterValues();
    this.getDemographicsQuestions();
    this.getDemoGraphicDynamicValues();
  }
}
