import { ComponentCanDeactivate } from './component-can-deactivate';
import { NgForm } from "@angular/forms";
import { DataService } from './data.service';
import { sessionData } from 'src/models/data.model';
import { Directive } from "@angular/core";

@Directive()
export abstract class FormCanDeactivate extends ComponentCanDeactivate {

  abstract get form(): NgForm;

  constructor(public dataService: DataService) {
    super();
  }
  canDeactivate(): boolean {
    //this.sessionData = this.getData();
    return this.dataService.onUnload;//this.form.submitted || !this.form.dirty
  }
}
