import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Route, ActivatedRoute, ParamMap } from '@angular/router';
import { AdminService } from 'src/services/admin.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ConfigService } from 'src/services/config.service';
import { participantDetails, participantDetailsExport, participantAnswers,ParticipantDynamicValuesModel } from 'src/app/model/participant.model';
import { DatePipe } from '@angular/common';
import { ExcelExportService } from 'src/app/service/excel-export.service';
import { Options, Settings, DateRangeModel } from "ngx-datetime-range-picker/lib/interfaces";
import { ms_Config } from 'src/models/config.model';
//../../projects / ngx - datetime - range - picker / src / lib / interfaces
declare var require: any;
const moment = require("moment");

@Component({
  selector: 'app-admin-participant-answers',
  templateUrl: './admin-participant-answers.component.html',
  styleUrls: ['./admin-participant-answers.component.css']
})
export class AdminParticipantAnswersComponent implements OnInit {

  /* Important. Dont delete this comment.
     We used ngx-date-range-picker for status update in applicant page.
     We had issue in icon display. And log error due to path mis-match.
     Please edit ngx-datetime-range-picker/fesm5/ngx-datetime-range-picker.js
     to fix icon not display and console log error for fontaswesome icon.
  */
  @Output() messageToParent = new EventEmitter<string>();
  pid: string;
  sid: string;
  qcat: string;
  participantAnswers: participantAnswers[] = [];
  participantDetails: participantDetails = new participantDetails();
  participantDetailsExport: participantDetails;
  exportDetails: participantDetailsExport[] = [];
  lastUpdated: any;
  applicantStatus: string[];
  public itemListResponse: ParticipantDynamicValuesModel[] = [];
  //selectedDate: any;
  public min = new Date(1900, 1, 0, 0, 0);
  rawDate = new Date();
  rawHour = this.rawDate.getHours();
  rawMin = this.rawDate.getMinutes();
  dateonly = this.rawDate.getDate();
  monthonly = this.rawDate.getMonth() + 1;
  yearonly = this.rawDate.getFullYear();
  setMaxDate = (this.monthonly + "-" + this.dateonly + "-" + this.yearonly).toString();

  fullHour = (this.rawHour + ":" + this.rawMin).toString();
  public tempLastUpdate: string = "01-01-2017";;
  public selectedOption = "daily";
  public selectedOptions: any;
  public dateTimeRangePickerSettings: Settings = this.getDateTimeRangeSettings();
  public dateRangePickerOptions: Options = {};
  public printSelectedDate;
  dateModel: Date = new Date();

  public onFilterChange(event, filter) {
    if (typeof event.defaultPrevented !== "undefined") {
      event.preventDefault();
    }
    if (filter === "level") {
      this.dateTimeRangePickerSettings = this.getDateTimeRangeSettings();
      this.dateTimeRangePickerSettings.type = event.value;
    }
  }
   
  public getDateTimeRangeSettings(): Settings {
    return {
      singleDatePicker: true,
      //displayBeginDate: false,
      displayEndDate: true,
      retailCalendar: false,
      timezoneSupport: false,
      timePicker: true,
      type: this.selectedOption,
      viewDateFormat: "MM-DD-YYYY",
      placeholder: "Date Time Range",
      inputDateFormat: "MM-DD-YYYY"
    };
  }

  public printDate(date): string {
    if (!date || !date[this.selectedOption]) {
      return;
    }
    return JSON.stringify(date[this.selectedOption], null, 4).trim();
  }

  constructor(private route: ActivatedRoute, private admin: AdminService, private router: Router, private configService: ConfigService, private exportExcel: ExcelExportService) {

    this.pid = this.route.snapshot.paramMap.get("pid");
    this.sid = this.route.snapshot.paramMap.get("sid");

    this.selectedOptions = {
      dateTimeRange: { 
        daily: {
          startDate: "01-01-2017",
          endDate: this.tempLastUpdate,//moment().format("MM-DD-YYYY"),
          minDate: "01-01-2017",
          maxDate: this.setMaxDate,
          startTime: "13:00",
          endTime: this.fullHour
        } as Options
      } as DateRangeModel
    };

    this.configService.getAppConfigByKey('Applicant_Status').subscribe(response => {
      this.applicantStatus = response.valText.split('|');
    });
    if (parseInt(this.pid) > 0 && parseInt(this.sid) > 0) {
      var datePipe = new DatePipe('en-US');

      this.admin.getParticipantDetails(parseInt(this.pid), parseInt(this.sid)).subscribe(response => {
        this.participantDetails = response;

        this.participantDetails.gender = this.participantDetails.gender == "M" ? "Male" : this.participantDetails.gender == "F" ? "Female" : "N/A";
        this.participantDetails.dob = this.participantDetails.dob == null || this.participantDetails.dob == "" ? "N/A" : datePipe.transform(this.participantDetails.dob, 'MM/dd/yyyy');
        this.participantDetails.bmi = this.participantDetails.bmi == null || this.participantDetails.bmi == "" ? "N/A" : this.participantDetails.bmi;
        this.participantDetails.weight = this.participantDetails.weight == null || this.participantDetails.weight == "" ? "N/A" : this.participantDetails.weight;
        this.participantDetails.status = this.participantDetails.status == null || this.participantDetails.status == "" ? "N/A" : this.participantDetails.status;
        //alert(this.participantDetails.Dynamic_Options);
        this.participantDetails.Dynamic_Options = this.participantDetails.Dynamic_Options == null || this.participantDetails.Dynamic_Options == "" ? "N/A" : this.participantDetails.Dynamic_Options;
        if (this.participantDetails.Dynamic_Options != "N/A") {
        this.itemListResponse = <ParticipantDynamicValuesModel[]>JSON.parse(this.participantDetails.Dynamic_Options);
        }
          //this.lastUpdated = new Date(datePipe.transform(currentDate, 'MM/dd/yyyy HH:mm'));
        //alert(itemListResponse);
        if (this.participantDetails.lastupdated != null) {
          this.lastUpdated = new Date(datePipe.transform(this.participantDetails.lastupdated, 'MM/dd/yyyy HH:mm'));
          this.tempLastUpdate = datePipe.transform(this.participantDetails.lastupdated, 'MM-dd-yyyy');
          this.fullHour = datePipe.transform(this.participantDetails.lastupdated, 'HH:mm');
          this.dateModel = this.lastUpdated;
        }
        //else {
        //  let currentDate = new Date();
        //  this.lastUpdated = new Date(datePipe.transform(currentDate, 'MM/dd/yyyy HH:mm'));
        //  this.tempLastUpdate = datePipe.transform(currentDate, 'MM-dd-yyyy');
        //  this.fullHour = datePipe.transform(currentDate, 'HH:mm');
        //  this.dateModel = this.lastUpdated;
        //}
        
        this.selectedOptions = {
          dateTimeRange: {
            daily: {
              startDate: "01-01-2017",
              endDate: this.tempLastUpdate,//moment().format("MM-DD-YYYY"),
              minDate: "01-01-2017",
              maxDate: this.setMaxDate,
              startTime: "13:00",
              endTime: this.fullHour
            } as Options
          } as DateRangeModel
        };
        this.participantDetailsExport = this.participantDetails;
      });
      this.admin.getParticipantAttempts(parseInt(this.pid), parseInt(this.sid))
        .subscribe(r => {
          this.participantAnswers = r;
          //if ((<any>this.participantAnswers).length <= 0) {
          //  this.router.navigate(['adminhome/notfound']);
          //}
        }, error => {
          this.router.navigate(['adminhome/notfound'])
        });
    }
  }

  userId: string;
  ngOnInit() {
  }
  ngOnDestroy() {
  }
  getAnswers() {
  }
  lastUpdatedChange(event, date) {
    var datePipe = new DatePipe('en-US');
    
    this.lastUpdated = new Date(event.endDate + " " + event.endTime);
    //this.updateStatus(event);
    this.participantDetails.lastupdated = datePipe.transform(this.lastUpdated, 'yyyy-MM-dd HH:mm:ss');
    if (this.participantDetails.status == "N/A") {
      alert("Please select valid status");
      return false;
    }
    if (this.participantDetails.status != null && this.participantDetails.status != "" && this.participantDetails.lastupdated != null) {
      this.admin.postUpdateParticipantStatus(this.participantDetails).subscribe(response => {
        this.participantDetailsExport = this.participantDetails;
        alert('Status updated successfully!');
      });
    }
  }
  updateStatus(event) {
    var datePipe = new DatePipe('en-US');
    let currentDate = new Date();
    this.lastUpdated = new Date(datePipe.transform(currentDate, 'MM/dd/yyyy HH:mm'));

    this.participantDetails.lastupdated = datePipe.transform(this.lastUpdated, 'yyyy-MM-dd HH:mm:ss');
    if (this.participantDetails.status == "N/A") {
      alert("Please select valid status");
      return false;
    }
    if (this.participantDetails.status != null && this.participantDetails.status != "" && this.participantDetails.lastupdated != null) {
      this.admin.postUpdateParticipantStatus(this.participantDetails).subscribe(response => {
        this.participantDetailsExport = this.participantDetails;

        //if (confirm('Status updated Successfully'))
        //  this.router.navigateByUrl("adminhome");        
        alert('Status updated successfully!');
      });
    }
  }
  exportAsXLSX(): void {
    this.exportDetails = [];
    var datePipe = new DatePipe('en-US');

    this.exportDetails.push({
      Applicant_Name: this.participantDetailsExport.lastName + ' ' + this.participantDetailsExport.firstName,
      Email_Address: this.participantDetailsExport.emailId,
      Contact_number: this.participantDetailsExport.phone,
      Preferred_Contact_time: this.participantDetailsExport.when_To_Call,
      Study_Number: this.participantDetailsExport.studyName,
      Status: this.participantDetailsExport.status,
      Birthdate: this.participantDetailsExport.dob,
      Gender: this.participantDetailsExport.gender,
      BMI: this.participantDetailsExport.bmi,
      Weight: this.participantDetailsExport.weight,
      Last_updated: datePipe.transform(this.participantDetailsExport.lastupdated, 'dd/MM/yyyy HH:mm')
    });

    this.exportDetails.push({ Applicant_Name: "" })
    this.exportDetails.push({ Applicant_Name: "" })
    this.exportDetails.push({ Applicant_Name: "Question", Email_Address: "Attempt 1", Contact_number: "Attempt 2", Preferred_Contact_time: "Attempt 3" });

    for (let i = 0; i < this.participantAnswers.length; i++) {
      this.exportDetails.push(
        {
          Applicant_Name: this.participantAnswers[i].questionText,
          Email_Address: this.participantAnswers[i].answer1,
          Contact_number: this.participantAnswers[i].answer2,
          Preferred_Contact_time: this.participantAnswers[i].answer3
        });
    }
    let fileName = this.exportDetails[0].Applicant_Name;
    this.exportExcel.exportAsExcelFile(this.exportDetails, fileName);
  }
  getResponseFromChild(date: string) {
    console.log(date);
    var datePipe = new DatePipe('en-US');
    this.lastUpdated = new Date(date);

    //this.updateStatus(event);
    this.participantDetails.lastupdated = datePipe.transform(this.lastUpdated, 'yyyy-MM-dd HH:mm:ss');
    if (this.participantDetails.status == "N/A") {
      alert("Please select valid status");
      return false;
    } 
    if (this.participantDetails.status != null && this.participantDetails.status != "" && this.participantDetails.lastupdated != null) {
      this.admin.postUpdateParticipantStatus(this.participantDetails).subscribe(response => {
        this.participantDetailsExport = this.participantDetails;
        alert('Status updated successfully!');
      });
    }
  }
  //hide(event) {
  //  alert('sd')
  //}
}
