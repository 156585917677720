import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup, Validators, FormArray,
  NgForm
} from '@angular/forms';
import { Router } from '@angular/router';
import { studyModel, studyScheduleModel, screeningScheduleModel, studyRequirementsModel, studyCommitmentModel, studyResponse } from 'src/models/study.model';
import { AdminService } from 'src/services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/services/config.service';
import { AdminHomeComponent } from '../admin_home/admin_home.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormCanDeactivate } from 'src/services/form-can-deactivate';
import { DataService } from 'src/services/data.service';
import { configModel } from 'src/models/config.model';

declare var $: any;

@Component({
  providers: [AdminHomeComponent],
  selector: 'app-admin-study-detail',
  templateUrl: './admin_study_detail.component.html',
  styleUrls: ['./admin_study_detail.component.css']
})

export class AdminStudyDetailComponent extends FormCanDeactivate {


  @ViewChild('f', { static: true })
  form: NgForm;

  config = {
    uiColor: '#ffffff',
    toolbarGroups: [{ name: 'basicstyles', groups: ['basicstyles'] },
    { name: 'styles' },],
    skin: 'kama',
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    extraPlugins: 'divarea,smiley,justify,indentblock,colordialog',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { 'color': '#(color)' }
    },
    height: 200,
    width: 1080,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div',
    autoParagraph: false,
    allowedContent: true,
    allow: true,
  }
  configfr = {
    uiColor: '#ffffff',
    toolbarGroups: [{ name: 'basicstyles', groups: ['basicstyles'] },
    { name: 'styles' },],
    skin: 'kama',
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    extraPlugins: 'divarea,smiley,justify,indentblock,colordialog',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { 'color': '#(color)' }
    },
    height: 140,
    width: 500,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div',
    autoParagraph: false,
    allowedContent: true,
    allow: true,


  }
  configCom = {
    uiColor: '#ffffff',
    toolbarGroups: [{ name: 'basicstyles', groups: ['basicstyles'] },
    { name: 'styles' },],
    skin: 'kama',
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    extraPlugins: 'divarea,smiley,justify,indentblock,colordialog',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { 'color': '#(color)' }
    },
    height: 140,
    width: 340,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div',
    autoParagraph: false,
    allowedContent: true,
    allow: true,


  }
  displayChar: boolean = false;
  display: boolean = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagsCtrl = new FormControl();
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = [];
  test12: boolean = true;
  screenDayNo: number = 1;
  studyNumber: number = 1;
  lstScreeningSchedule: screeningScheduleModel[] = [];
  lstStudySchedule: studyScheduleModel[] = [];
  tagArray: string[] = [];
  refered: string;
  tagRequiredFlag: boolean = false;
  ageBigFlag: boolean = false;
  bmiBigFlag: boolean = false;
  weightBigFlag: boolean = false;
  dayRequiredFlag: boolean = false;
  ageSmallFlag: boolean = false;
  weightSmallFlag: boolean = false;
  bmiSmallFlag: boolean = false;
  formValidFlag: boolean = true;
  reqEnableFlag: boolean = false;
  required: boolean = false;
  screenTimeFlag: boolean = false;
  isPreviewVisible: boolean = true;
  isTabClicked: boolean = false;
  visitsDescFlag: boolean = false;
  questionFlag: boolean = true;
  UserIdLogged: string = "";
  public IsRefered: boolean = false;
  configModel: configModel;
  studyCheckModel: string;

  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  printableObj: Object;
  studymodel: studyModel;
  sCommitmentModel: studyCommitmentModel;
  _studyId: number = 0;

  frmGrpAddDay: FormGroup;
  frmAryStudySchedule: FormArray;

  frmGrpScreeningAddDay: FormGroup;
  frmAryScreeningSchedule: FormArray;

  frmGrpAddRequirement: FormGroup;
  frmAryStudyRequirement: FormArray;

  btntab: ElementRef;
  pageHeader: string = "New Study";
  btnName: string = "save";
  disableBtn: boolean = true;
  screeningDays = "";
  showToggle: boolean = false;
  _studyResponse: studyResponse;

  constructor(private route: ActivatedRoute, private _route: Router, private studyService: AdminService,
    private fb: FormBuilder, private router: Router, private configService: ConfigService, private adminHome: AdminHomeComponent, public dataService: DataService,
    private elem: ElementRef) {
    super(dataService);

    this.studymodel = new studyModel();

    this.sCommitmentModel = new studyCommitmentModel();

    this.filteredTags = this.tagsCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));

  }


  handleChange(e) {
    let isChecked = e.checked;
    this.studymodel.isPublished = isChecked;
  }
  add(event: MatChipInputEvent): void {
    // Add tags only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    //if (!this.matAutocomplete.isOpen) {
    const input = event.input;
    const value = event.value;

    // Add our tags
    if ((value).trim()) {
      if (value.length >= 3) {
        if ((this.tags.filter(x => x.toString().toLowerCase() == value.trim().toLowerCase()).length == 0) && this.tags.length < 50) {
          this.tags.push(value.trim());
        }
        else {
          this.display = true;
        }
      } else {
        this.displayChar = true;
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.tagsCtrl.setValue(null);
    this.tagRequiredFlag = false;
    //}
  }
  // Remove tag
  remove(tag: string): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  // Push value from autocomplete
  selected(event: MatAutocompleteSelectedEvent): void {

    if ((this.tags.filter(x => x.toString().toLowerCase() == event.option.viewValue.toLowerCase()).length == 0) && this.tags.length < 50) {
      this.tags.push(event.option.viewValue);
    } else {
      this.display = true;
    }

    this.tagInput.nativeElement.value = '';
    this.tagsCtrl.setValue(null);
    this.tagRequiredFlag = false;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

  userId: string;
  activeTab: string;
  selectedDays = [];

  ngOnInit() {


    this.configService.getAppConfig()
      .subscribe(
        (resp: configModel) => {
          this.configModel = resp
          localStorage.setItem('config', JSON.stringify(resp));
          let ck = new configModel();
          this.refered = ck.getConfigkeyname('Refered');
        });

    this.StudyModel.studySummary = '';
    this.StudyModel.isPublished = false;
    this.sCommitmentModel.commitmentscreeningDays = '1';
    this.sCommitmentModel.dcd = '1';
    this.sCommitmentModel.followupVisits = '0';

    this.route.fragment.subscribe((fragment: string) => {
      if (fragment == 'display') {
        this.activeTab = "display";
        this.showToggle = true;
        this.isPreviewVisible = true;
      }
      else if (fragment == 'stratification') {
        this.activeTab = 'stratification';
        this.showToggle = false;
        this.isPreviewVisible = false;
      } else if (fragment == 'question') {
        this.activeTab = 'question';
        this.showToggle = false;
        this.isPreviewVisible = false;
      }
    })

    this.frmGrpAddDay = this.fb.group({
      frmAryStudySchedule: this.fb.array([this.addDayFormGroup("", "")])

    });

    this.frmGrpScreeningAddDay = this.fb.group({
      frmAryScreeningSchedule: this.fb.array(
        [this.addScreeningDayFormGroup(false, false, false, false, false, false, false, "", "")])
    });

    this.frmGrpAddRequirement = this.fb.group({
      frmAryStudyRequirement: this.fb.array([this.addRequirementFormGroup("")])
    });
    this.route.paramMap.subscribe(params => {
      this.studymodel.studyId = parseInt(params.get('id'));
      if (this.studymodel.studyId > 0) {
        this._studyId = this.studymodel.studyId;
        this.dataService.setData(this._studyId);
        this.editStudy();
        this.pageHeader = "Edit Study";
        this.btnName = "Update";
      }
      else {
        this.pageHeader = "New Study";
        this.btnName = "Save Changes";
        this.lstScreeningSchedule.push({
          monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false, sno: this.screenDayNo, screeningTimes: "", screeningscheduleDays: "", isValidDays: true, isValidTimes: true
        })
      }
    });
    this.getAllTaglist();
    this.getLogginedUserId();
    this.getFormGroupData();
    this.studyCheckModel = JSON.parse(JSON.stringify(this.studymodel));
  }

  getLogginedUserId() {

    this.studyService.getUserId()
      .subscribe(response => {
        this.UserIdLogged = String(response);
      });
  }

  onDaySelected(event, ind, isChecked, value: string) {
    if (isChecked) {
      if ((this.selectedDays.filter(dayItem => dayItem.toString().toLowerCase() == value.trim().toLowerCase()).length == 0)) {
        this.selectedDays.push(value);
      }
      for (let b = 0; b <= this.selectedDays.length; b++) {
        if (this.selectedDays[0] = "monday") {
          this.screeningDays = "Monday";
        }
      }
    } else {
      let removeIndex = this.selectedDays.findIndex(itm => itm === value);
      if (removeIndex !== -1)
        this.selectedDays.splice(removeIndex, 1);
    }
  }

  /* Number increment/decrement */

  btnClickAddDay(day): void {
    this.lstStudySchedule.push({
      screeningDays: day, followupDays: "", isValid: true
    })
  }

  btnClickScreeningAddDay(): void {
    this.screenDayNo = this.screenDayNo + 1;

    this.lstScreeningSchedule.push({
      monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false, sno: this.screenDayNo, screeningTimes: "", screeningscheduleDays: "", isValidDays: true, isValidTimes: true
    })

    if (this.lstScreeningSchedule.length == 7) {
      this.disableBtn = false;
    } else {
      this.disableBtn = true;
    }
  }
  btnClickRequirementAdd(): void {
    this.Requirements.push(this.addRequirementFormGroup(""));
    if (this.Requirements.length == 10) {
      this.reqEnableFlag = true;
    }
    else {
      this.reqEnableFlag = false;
    }
  }
  btnClickRemoveDay(day): void {
    this.lstStudySchedule = this.lstStudySchedule.filter(x => x.screeningDays != day);
  }

  removeScreeningDay(rowno) {
    let removeScreening = this.lstScreeningSchedule.filter(x => x.sno == rowno)[0];
    if (removeScreening.monday == true) {
      this.selectedDays = this.selectedDays.filter(x => x != "monday");
      this.btnClickRemoveDay('monday');
    }
    if (removeScreening.tuesday == true) {
      this.selectedDays = this.selectedDays.filter(x => x != "tuesday");
      this.btnClickRemoveDay('tuesday');
    }
    if (removeScreening.wednesday == true) {
      this.selectedDays = this.selectedDays.filter(x => x != "wednesday");
      this.btnClickRemoveDay('wednesday');
    }
    if (removeScreening.thursday == true) {
      this.selectedDays = this.selectedDays.filter(x => x != "thursday");
      this.btnClickRemoveDay('thursday');
    }
    if (removeScreening.friday == true) {
      this.selectedDays = this.selectedDays.filter(x => x != "friday");
      this.btnClickRemoveDay('friday');
    }
    if (removeScreening.saturday == true) {
      this.selectedDays = this.selectedDays.filter(x => x != "saturday");
      this.btnClickRemoveDay('saturday');
    }
    if (removeScreening.sunday == true) {
      this.selectedDays = this.selectedDays.filter(x => x != "sunday");
      this.btnClickRemoveDay('sunday');
    }

    this.lstScreeningSchedule = this.lstScreeningSchedule.filter(x => x.sno != rowno);
    if (this.lstScreeningSchedule.length < 7) {
      this.disableBtn = true;
    }
  }
  btnClickScreeningRemoveDay(rowIndex): void {
    this.ScreeningSchedule.removeAt(rowIndex);
    if (this.ScreeningSchedule.length < 7) {
      this.disableBtn = false;
    }
  }
  btnClickRequirementRemove(rowIndex): void {
    this.Requirements.removeAt(rowIndex);
    if (this.Requirements.length < 10) {
      this.reqEnableFlag = false;
    }
  }

  addDayFormGroup(sDays: string, fDays: string): FormGroup {
    return this.fb.group({
      ScreeningDays: [sDays, Validators.required],
      FollowUpDays: [fDays, Validators.required]
    })
  }
  addRequirementFormGroup(requirement: string): FormGroup {
    return this.fb.group({
      Requirements: [requirement, Validators.required]
    })
  }

  addScreeningDayFormGroup(mon: boolean, tue: boolean, wed: boolean, thu: boolean, fri: boolean, sat: boolean, sun: boolean, screenDays: string, stime: string): FormGroup {
    return this.fb.group({
      Monday: [mon],
      Tuesday: [tue],
      Wednesday: [wed],
      Thursday: [thu],
      Friday: [fri],
      Saturday: [sat],
      Sunday: [sun],
      ScreeningscheduleDays: [screenDays, Validators.required],
      ScreeningTimes: [stime, Validators.required]
    })
  }

  public get StudySchedule(): FormArray {
    return (<FormArray>this.frmGrpAddDay.get('frmAryStudySchedule'));
  }

  public get ScreeningSchedule(): FormArray {
    return (<FormArray>this.frmGrpScreeningAddDay.get('frmAryScreeningSchedule'));
  }

  public get Requirements(): FormArray {
    return (<FormArray>this.frmGrpAddRequirement.get('frmAryStudyRequirement'));
  }

  public get StudyModel(): studyModel {

    return this.studymodel
  }
  getAllTaglist() {
    this.studyService.getAllTagsForAdmin()
      .subscribe(tags => {
        for (let i = 0; i < tags.length; i++) {
          if (tags[i].studyTag.split(',').length > 0) {
            for (let j = 0; j < tags[i].studyTag.split(',').length; j++) {
              this.tagArray.push(tags[i].studyTag.split(',')[j]);
            }
          }
          else {
            this.tagArray.push(tags[i].studyTag);
          }
        }

        this.tagArray = Array.from(this.tagArray.reduce((m, t) => m.set(t, t), new Map()).values());
        this.tagArray = this.tagArray.sort((a, b) => a.localeCompare(b));
        this.allTags = this.tagArray.filter(n => n);
      })
  }

  editStudy() {
    this.studyService.getStudyDetails(this.studymodel.studyId)
      .subscribe(_s => {
        this.getJSONData(_s)
        this.studyCheckModel = JSON.parse(JSON.stringify(_s));
      }, error => {
        this._route.navigate(['adminhome/notfound'])
      });
  }


  addStudy() {
    if (this.selectedDays.length > 0 || this.studymodel.studyScreeningTime) { }
    this.studyService.postStudy(this.studymodel)
      .subscribe(res => {
        this.studyCheckModel = JSON.parse(JSON.stringify(this.studymodel));
        this._studyResponse = JSON.parse(res);
        this.studymodel.studyId = this._studyResponse.studyId;
        this._studyId = this._studyResponse.studyId;
        this.dataService.setData(this._studyId);
        alert('Study Added & Approved Successfully');
        this.pageHeader = "Study " + this.studymodel.studyName;
        this.btnName = "Update";
      })
  }
  updateStudy() {

    this.studyService.updateStudy(this.studymodel)
      .subscribe(res => {
        this.studyCheckModel = JSON.parse(JSON.stringify(this.studymodel));
        alert('Study Updated & Approved Successfully')
      });
  }
  btnAction(btnid) {
    this.formValidFlag = true;

    //Study name, compensation, summary validation
    if (this.studymodel.studyName == '' || this.studymodel.studyName == null || this.studymodel.studyName == undefined) {
      this.formValidFlag = false;
    }
    if (this.studymodel.studySummary == '' || this.studymodel.studySummary == null || this.studymodel.studySummary == undefined) {
      this.formValidFlag = false;
    }

    //Tags array validation

    if (this.tags.length < 1) {
      this.formValidFlag = false;
      this.tagRequiredFlag = true;
    }
    if (this.tags.length >= 1) {
      this.tagRequiredFlag = false;
    }
    // Commitment section form validation    
    if (this.sCommitmentModel.commitmentscreeningDays == '' || this.sCommitmentModel.commitmentscreeningDays == null || this.sCommitmentModel.commitmentscreeningDays == undefined) {
      this.formValidFlag = false;
    }

    if (this.sCommitmentModel.time == '' || this.sCommitmentModel.time == null || this.sCommitmentModel.time == undefined) {
      this.formValidFlag = false;
    }

    if (this.sCommitmentModel.dcd == '' || this.sCommitmentModel.dcd == null || this.sCommitmentModel.dcd == undefined) {
      this.formValidFlag = false;
    }

    if (this.sCommitmentModel.description == '' || this.sCommitmentModel.description == null || this.sCommitmentModel.description == undefined) {
      this.formValidFlag = false;
    }


    if (this.sCommitmentModel.followupVisits == '' || this.sCommitmentModel.followupVisits == null || this.sCommitmentModel.followupVisits == undefined) {
      if (this.sCommitmentModel.followupVisits != '0') {
        this.formValidFlag = false;
      }
    }

    if (Number(this.sCommitmentModel.followupVisits) > 0) {
      if (this.sCommitmentModel.followupTime == '' || this.sCommitmentModel.followupTime == null || this.sCommitmentModel.followupTime == undefined) {
        this.formValidFlag = false;
        this.visitsDescFlag = true;
      }
    } else {
      this.visitsDescFlag = false;
    }
    // Commitment section form validation ends

    // Screening days validation
    if (this.selectedDays.length < 1) {
      this.dayRequiredFlag = true;
    }

    for (let i = 0; i < this.lstScreeningSchedule.length; i++) {
      if (this.lstScreeningSchedule[i].screeningTimes == '') {
        this.lstScreeningSchedule[i].isValidTimes = false;
        this.formValidFlag = false;
      }
      else {
        this.lstScreeningSchedule[i].isValidTimes = true;
      }
    }

    for (let i = 0; i < this.lstScreeningSchedule.length; i++) {
      if (this.lstScreeningSchedule[i].monday == false && this.lstScreeningSchedule[i].tuesday == false &&
        this.lstScreeningSchedule[i].wednesday == false && this.lstScreeningSchedule[i].thursday == false &&
        this.lstScreeningSchedule[i].friday == false && this.lstScreeningSchedule[i].saturday == false &&
        this.lstScreeningSchedule[i].sunday == false) {
        this.lstScreeningSchedule[i].isValidDays = false;
        this.formValidFlag = false;
      }
      else {
        this.lstScreeningSchedule[i].isValidDays = true;
      }
    }

    //Followupdays validation
    for (let i = 0; i < this.lstStudySchedule.length; i++) {
      if (this.lstStudySchedule[i].followupDays == "") {
        this.lstStudySchedule[i].isValid = false;
      }
      else {
        this.lstStudySchedule[i].isValid = true;
      }
    }

    if (this.formValidFlag == false) {
      alert("Please fill all the required fields");
      return false;
    }

    if (this.lstScreeningSchedule.length == 0) {
      alert('Please add Screening Schedule!');
      return false;
    }
    let flagRequirement = true;
    if (this.frmGrpAddRequirement.value.frmAryStudyRequirement.length == 0) {
      flagRequirement = false;
    }

    for (let zcntrl of this.frmGrpAddRequirement.value.frmAryStudyRequirement) {
      let src = new studyRequirementsModel();
      src.requirement = zcntrl.Requirements;
      if (zcntrl.Requirements == "" || zcntrl.Requirements == null) {
        flagRequirement = false;
        break;
      }
    }

    if (!flagRequirement) {
      alert("Requirements should not be empty")
      return false;
    }
    this.getFormGroupData();

    this.printableObj = this.StudyModel;

    if (btnid == 1) {

      //write update flage isapproved=0 and insert update into temp table
      this.studyService.updateStudyWithFlag(this.studymodel)
        .subscribe(res => {
          this.studyCheckModel = JSON.parse(JSON.stringify(this.studymodel));
          this._studyResponse = JSON.parse(res);
          this.studymodel.studyId = this._studyResponse.studyId;
          this._studyId = this._studyResponse.studyId;
          this.dataService.setData(this._studyId);
          alert('Study Updated Successfully for approval')
        });
    }

    else {

      if (this.btnName == "Save Changes")
        this.addStudy();
      else if (this.btnName == "Update")
        this.updateStudy();
    }
  }

  isJSON(string): boolean {
    try {
      if (JSON.parse(string) == null ||
        JSON.parse(string) == undefined)
        return false;
    } catch (error) {
      return false;
    }
    return true;
  }

  //Retruning data from server. Edit page
  getJSONData(response: any) {
    this.studymodel = response;
    this.pageHeader = "Study " + this.studymodel.studyName;

    //Return back Tags
    let stdTags = this.studymodel.studyTag;
    if (stdTags != null) {
      this.tags = stdTags.split(',');
    }

    //Return study schedule data

    if (this.isJSON(this.studymodel.studySchedule)) {
      let arr: studyScheduleModel[] = JSON.parse(this.studymodel.studySchedule);
      this.StudySchedule.removeAt(0);
      for (let i = 0; i < arr.length; i++) {
        this.lstStudySchedule.push({ screeningDays: arr[i].screeningDays, followupDays: arr[i].followupDays, isValid: arr[i].isValid })
      }
      for (let i = 0; i < this.lstStudySchedule.length; i++) {
        if (this.lstStudySchedule[i].followupDays == "") {
          this.lstStudySchedule[i].isValid = false;
          this.formValidFlag = false;
        }
        else {
          this.lstStudySchedule[i].isValid = true;
        }
      }
    }
    if (this.studymodel.screeningSchedule == null) {
      this.lstScreeningSchedule.push({
        monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false, sno: this.screenDayNo, screeningTimes: "", screeningscheduleDays: "", isValidDays: true, isValidTimes: true
      });
    }
    if (this.isJSON(this.studymodel.screeningSchedule)) {
      let arr: screeningScheduleModel[] = JSON.parse(this.studymodel.screeningSchedule);
      if (arr.length == 0) {
        this.lstScreeningSchedule.push({
          monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false, sno: this.screenDayNo, screeningTimes: "", screeningscheduleDays: "", isValidDays: true, isValidTimes: true
        })
      }
      for (let i = 0; i < arr.length; i++) {
        this.lstScreeningSchedule.push({
          monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false, sno: this.screenDayNo, screeningTimes: arr[i].screeningTimes, screeningscheduleDays: "", isValidDays: true, isValidTimes: true
        })
        this.screenDayNo = this.screenDayNo + 1
        let scrnDays = arr[i].screeningscheduleDays.split('|');

        for (let j = 0; j < scrnDays.length; j++) {
          if (scrnDays[j] == 'monday') {
            this.lstScreeningSchedule[i].monday = true;
          }
          else if (scrnDays[j] == 'tuesday') {
            this.lstScreeningSchedule[i].tuesday = true;
          }
          else if (scrnDays[j] == 'wednesday') {
            this.lstScreeningSchedule[i].wednesday = true;
          }
          else if (scrnDays[j] == 'thursday') {
            this.lstScreeningSchedule[i].thursday = true;
          }
          else if (scrnDays[j] == 'friday') {
            this.lstScreeningSchedule[i].friday = true;
          }
          else if (scrnDays[j] == 'saturday') {
            this.lstScreeningSchedule[i].saturday = true;
          }
          else if (scrnDays[j] == 'sunday') {
            this.lstScreeningSchedule[i].sunday = true;
          }
        }
      }

      this.ScreeningSchedule.removeAt(0);
      for (let i = 0; i < this.lstScreeningSchedule.length; i++) {
        if (this.lstScreeningSchedule[i].screeningTimes == '') {
          this.lstScreeningSchedule[i].isValidTimes = false;
        }
        else {
          this.lstScreeningSchedule[i].isValidTimes = true;
        }
      }
    }
    if (this.isJSON(this.studymodel.studyRequirements)) {
      let arr: studyRequirementsModel[] = JSON.parse(this.studymodel.studyRequirements);
      this.Requirements.removeAt(0);
      arr.forEach((value, index) => {
        this.Requirements.push(
          this.addRequirementFormGroup(value.requirement));
      });
    }
    if (this.isJSON(this.studymodel.studyCommitment)) {
      let arr: any = JSON.parse(this.studymodel.studyCommitment);
      this.sCommitmentModel.commitmentscreeningDays = "1";
      this.sCommitmentModel.commitmentscreeningDays = arr[0].commitmentscreeningDays;
      this.sCommitmentModel.time = arr[0].time;
      this.sCommitmentModel.dcd = arr[1].dcd;
      this.sCommitmentModel.description = arr[1].description;
      this.sCommitmentModel.followupVisits = arr[2].followupVisits;
      this.sCommitmentModel.followupTime = arr[2].followupTime;
    }

  }

  getFormGroupData() {
    let _json = [];

    this.studymodel.studyTag = this.tags.join();

    for (let cntrl of this.lstStudySchedule) {
      let screeningDays = cntrl.screeningDays;
      let followUpDays = cntrl.followupDays;
      _json.push({ "screeningDays": screeningDays, "followupDays": followUpDays });
    }
    this.studymodel.studySchedule = JSON.stringify(_json);

    _json = [];
    for (let scntrl of this.lstScreeningSchedule) {
      if (scntrl.monday == true || scntrl.tuesday == true || scntrl.wednesday == true || scntrl.thursday == true || scntrl.friday == true || scntrl.saturday == true || scntrl.sunday == true) {
        let screeningDays = "";
        screeningDays = screeningDays.concat(scntrl.monday == true ? 'monday|' : '');
        screeningDays = screeningDays.concat(scntrl.tuesday == true ? 'tuesday|' : '');
        screeningDays = screeningDays.concat(scntrl.wednesday == true ? 'wednesday|' : '');
        screeningDays = screeningDays.concat(scntrl.thursday == true ? 'thursday|' : '');
        screeningDays = screeningDays.concat(scntrl.friday == true ? 'friday|' : '');
        screeningDays = screeningDays.concat(scntrl.saturday == true ? 'saturday|' : '');
        screeningDays = screeningDays.concat(scntrl.sunday == true ? 'sunday|' : '');
        screeningDays = screeningDays.substring(0, screeningDays.length - 1);

        _json.push({ "screeningscheduleDays": screeningDays, "screeningTimes": scntrl.screeningTimes });
      }
    }
    this.studymodel.screeningSchedule = JSON.stringify(_json);

    _json = [];
    for (let zcntrl of this.frmGrpAddRequirement.value.frmAryStudyRequirement) {
      let src = new studyRequirementsModel();
      src.requirement = zcntrl.Requirements;
      _json.push({ "requirement": zcntrl.Requirements });
    }
    this.studymodel.studyRequirements = JSON.stringify(_json);

    _json = [];
    _json.push(
      {
        commitmentscreeningDays: this.sCommitmentModel.commitmentscreeningDays,
        time: this.sCommitmentModel.time
      },
      {
        dcd: this.sCommitmentModel.dcd,
        description: this.sCommitmentModel.description
      },
      {
        followupVisits: this.sCommitmentModel.followupVisits,
        followupTime: this.sCommitmentModel.followupTime
      }
    );
    this.StudyModel.studyCommitment = JSON.stringify(_json);

  }

  checkAgeMin(event) {
    if (Number(this.StudyModel.studyAgeMax) > 0 && Number(this.StudyModel.studyAgeMin) > 0) {
      if (event.target.value > Number(this.StudyModel.studyAgeMax)) {
        this.ageSmallFlag = true;
      } else {
        this.ageSmallFlag = false;
      }
      if (event.target.value < Number(this.StudyModel.studyAgeMax)) {
        this.ageBigFlag = false;
      }
    }
  }
  checkAgeMax(event) {
    if (Number(this.StudyModel.studyAgeMin) > 0 && Number(this.StudyModel.studyAgeMax) > 0) {
      if (event.target.value < Number(this.StudyModel.studyAgeMin)) {
        this.ageBigFlag = true;
      } else {
        this.ageBigFlag = false;
      }
      if (event.target.value > Number(this.StudyModel.studyAgeMin)) {
        this.ageSmallFlag = false;
      }
    }
  }
  checkBMIMin(event) {
    if (Number(this.StudyModel.studyBMIMax) > 0 && Number(this.StudyModel.studyBMIMin) > 0) {
      if (event.target.value > Number(this.StudyModel.studyBMIMax)) {
        this.bmiSmallFlag = true;
      } else {
        this.bmiSmallFlag = false;
      }
      if (event.target.value < Number(this.StudyModel.studyBMIMax)) {
        this.bmiBigFlag = false;
      }
    }
  }
  checkBMIMax(event) {
    if (Number(this.StudyModel.studyBMIMin) > 0 && Number(this.StudyModel.studyBMIMax) > 0) {
      if (event.target.value < Number(this.StudyModel.studyBMIMin)) {
        this.bmiBigFlag = true;
      } else {
        this.bmiBigFlag = false;
      }
      if (event.target.value > Number(this.StudyModel.studyBMIMin)) {
        this.bmiSmallFlag = false;
      }
    }
  }
  checkWeightMin(event) {
    if (Number(this.StudyModel.studyWeightMax) > 0 && Number(this.StudyModel.studyWeightMin) > 0) {
      if (event.target.value > Number(this.StudyModel.studyWeightMax)) {
        this.weightSmallFlag = true;
      } else {
        this.weightSmallFlag = false;
      }
      if (event.target.value < Number(this.StudyModel.studyWeightMax)) {
        this.weightBigFlag = false;
      }
    }
  }
  checkWeightMax(event) {
    if (Number(this.StudyModel.studyWeightMin) > 0 && Number(this.StudyModel.studyWeightMax) > 0) {
      if (event.target.value < Number(this.StudyModel.studyWeightMin)) {
        this.weightBigFlag = true;
      } else {
        this.weightBigFlag = false;
      }
      if (event.target.value > Number(this.StudyModel.studyWeightMin)) {
        this.weightSmallFlag = false;
      }
    }
  }
  numberOnly(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  alphabetsOnly(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (!(charCode >= 65 && charCode <= 122) && (charCode != 32 && charCode != 0)) {
      return false;
    }
    else if (charCode >= 91 && charCode <= 95) {
      return false;
    }
    return true;
  }
  alphaNumericOnly(event) {
    event = (event) ? event : window.event;
    var regex = new RegExp("^[a-zA-Z0-9 .]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
      return true;
    }
    return false;
  }
  onDaySelect(event, i, day) {
    if (event.target.checked) {
      this.lstScreeningSchedule[i].isValidDays = true;

      for (let k = 0; k < this.lstScreeningSchedule.length; k++) {
        if (day == "monday" && k != i) {
          if (this.lstScreeningSchedule[k].monday == true) {
            this.btnClickRemoveDay('monday');
          }
          this.lstScreeningSchedule[k].monday = false;
        }
        else if (day == "tuesday" && k != i) {
          if (this.lstScreeningSchedule[k].tuesday == true) {
            this.btnClickRemoveDay('tuesday');
          }
          this.lstScreeningSchedule[k].tuesday = false;
        }
        else if (day == "wednesday" && k != i) {
          if (this.lstScreeningSchedule[k].wednesday == true) {
            this.btnClickRemoveDay('wednesday');
          }
          this.lstScreeningSchedule[k].wednesday = false;
        }
        else if (day == "thursday" && k != i) {
          if (this.lstScreeningSchedule[k].thursday == true) {
            this.btnClickRemoveDay('thursday');
          }
          this.lstScreeningSchedule[k].thursday = false;
        }
        else if (day == "friday" && k != i) {
          if (this.lstScreeningSchedule[k].friday == true) {
            this.btnClickRemoveDay('friday');
          }
          this.lstScreeningSchedule[k].friday = false;
        }
        else if (day == "saturday" && k != i) {
          if (this.lstScreeningSchedule[k].saturday == true) {
            this.btnClickRemoveDay('saturday');
          }
          this.lstScreeningSchedule[k].saturday = false;
        }
        else if (day == "sunday" && k != i) {
          if (this.lstScreeningSchedule[k].sunday == true) {
            this.btnClickRemoveDay('sunday');
          }
          this.lstScreeningSchedule[k].sunday = false;
        }
      }
      if ((this.selectedDays.filter(dayItem => dayItem.toString().toLowerCase() == day.trim().toLowerCase()).length == 0)) {
        this.selectedDays.push(day);
      }
      this.btnClickAddDay(day);
    } else {
      let removeIndex = this.selectedDays.findIndex(itm => itm === day);
      if (removeIndex !== -1)
        this.selectedDays.splice(removeIndex, 1);
      this.btnClickRemoveDay(day);
    }
  }
  tabClicked(i) {
    this.isPreviewVisible = i == 1 ? true : false;
  }
  btnPreview() {
    this.studyService.getQSDemographics(isNaN(this._studyId) ? 0 : this._studyId)
      .subscribe(response => {
        if (response.length == 0) {
          alert('Stratification not found!');
          return false;
        }
        this.studyService.getQuestionsForAdmin(this._studyId, 'PreScreener')
          .subscribe(res => {
            if (res.length == 0) {
              alert('Questions not found!');
              return false;
            }
            else {
              this.router.navigate(['/ParticipantStudyDetail/', this._studyId], { state: { IsRefered: false } });
            }
          });
      });
  }
  IsAdminReferred() {
    this.IsRefered = true;
    this.studyService.getQSDemographics(isNaN(this._studyId) ? 0 : this._studyId)
      .subscribe(response => {
        if (response.length == 0) {
          alert('Stratification not found!');
          return false;
        }
        this.studyService.getStudyDetailsForParticipant(isNaN(this._studyId) ? 0 : this._studyId)
          .subscribe(response => {
            if (response.isPublished == false) {
              alert('Study is in Approval Stage.Please refer after the approval!');
              return false;
            }

            this.studyService.getQuestionsForAdmin(this._studyId, 'PreScreener')
              .subscribe(res => {
                if (res.length == 0) {
                  alert('Questions not found!');
                  return false;
                }
                else {
                  this.router.navigate(['/ParticipantStudyDetail/', this._studyId], { state: { IsRefered: this.IsRefered } });
                }
              });
          });
      });
  }
  cancel(event) {
    this.getFormGroupData();

    let strModelDemo = JSON.stringify(this.StudyModel);
    let checkModel = JSON.stringify(this.studyCheckModel);
    if (checkModel == strModelDemo) {
      this.router.navigateByUrl('adminhome');
      event.preventDefault();
    }
    else {
      if (confirm("You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?")) {
        this.router.navigateByUrl('adminhome');
        event.preventDefault();
      }
      else {

      }
    }
  }
  isPublishToggle() {
    if (this.StudyModel.isPublished) {

      if (this._studyId != 0) {
        this.studyService.getQSDemographics(isNaN(this._studyId) ? 0 : this._studyId)
          .subscribe(response => {
            if (response.length == 0) {
              alert('Unable to Activate. Stratification not found!');
              this.StudyModel.isPublished = false;
              return false;
            }
            this.studyService.getQuestionsForAdmin(this._studyId, 'PreScreener')
              .subscribe(res => {
                if (res.length == 0) {
                  alert('Unable to Activate. Questions not found!');
                  this.StudyModel.isPublished = false;
                  return false;
                }
                else {
                  this.StudyModel.isPublished = true;
                }
              });
          });
      }
      else {
        this.StudyModel.isPublished = false;
        alert('Unable to Activate. Study details not found!');
        return false;
      }
    }
  }
  getResponseFromChild(response: string) {
    this.questionFlag = false;
  }
}
