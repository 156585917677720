import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { AdminService } from 'src/services/admin.service';
import { studyModel } from 'src/models/study.model';
import { ParticipantService } from 'src/services/participant.service';
import { participantModel, participantExportExcel } from 'src/models/participant.model';
import { DataTableDirective } from 'angular-datatables';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigService } from 'src/services/config.service';
import { ExcelExportService } from 'src/app/service/excel-export.service';
import { MultiSelect } from 'primeng/multiselect';
import { applicantsFilter } from 'src/app/model/gridFilter.model';
import { DatePipe } from '@angular/common';
import { filter } from 'rxjs/operators';
import { NavigationTrackingService } from 'src/services/navigation-tracking.service';
import { Table } from 'primeng/table';
import { SelectItem } from 'primeng/api/selectitem';
import { ConfirmationService } from 'primeng/api';
declare var $;

@Component({
  selector: 'app-admin-participant-list',
  templateUrl: './admin-participant-list.component.html',
  styleUrls: ['./admin-participant-list.component.css']
})
export class AdminParticipantListComponent implements OnInit {

  @ViewChild('scrollTop', { static: true }) scrollTop: ElementRef;
  @ViewChild('search', { static: true }) search: ElementRef;
  @ViewChildren('studyNameClear') clearStudy: QueryList<MultiSelect>;
  @ViewChildren('nameClear') clearName: QueryList<MultiSelect>;
  @ViewChildren('whentocallClear') clearwhentocall: QueryList<MultiSelect>;
  @ViewChildren('ageClear') clearage: QueryList<MultiSelect>;
  @ViewChildren('genderClear') cleargender: QueryList<MultiSelect>;
  @ViewChildren('statusClear') clearstatus: QueryList<MultiSelect>;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dropdownList = [];
  sselecteditem: number = -1;
  availablestudies: studyModel;
  allparticipants: participantModel[];
  participantlist: participantModel[];
  allparticipantsFinalExport: participantExportExcel[] = [];
  participantObject: participantExportExcel = new participantExportExcel();
  filteredParticipantlist: participantModel[];
  createdDateFlag: boolean;
  cols: any[];
  name: SelectItem[] = [];
  phone: SelectItem[] = [];
  whentocall: SelectItem[] = [];
  age: SelectItem[] = [];
  gender: SelectItem[] = [];
  studynumber: SelectItem[] = [];
  status: SelectItem[] = [];
  applicantsFilter: applicantsFilter = new applicantsFilter();
  exportapplicantsFilter: applicantsFilter = new applicantsFilter();
  applicantFilterHier: string[] = [];
  selectedWhenToCall: string[] = [];
  checkedWhenToCall: string[] = [];
  selectedStudyNumber: string[] = [];
  checkedStudyNumber: string[] = [];
  selectedAge: number[] = [];
  checkedAge: number[] = [];
  selectedGender: string[] = [];
  checkedGender: string[] = [];
  selectedStatus: string[] = [];
  checkedStatus: string[] = [];
  isFromSessionStore = false;
  isFirstNavigation = false;
  isSearchFilterApplied = false;
  @ViewChild('dt') tableData: Table;
  searchFilterData: any[];
  sessionStore: any;
  isClearFilterOn = false;
  isDeleteEnabled = false;
  selectedApplicants: participantModel[];

  constructor(private studyService: AdminService, private participantService: ParticipantService, private confirmationService: ConfirmationService,
    private router: Router, private configService: ConfigService, private exportExcel: ExcelExportService, private navService: NavigationTrackingService) {
    this.isFirstNavigation = sessionStorage.getItem('isFirstNavigationToApplicants') === 'true';
  }
  userId: string;
  ngOnInit() {
    localStorage.setItem("isAdminUser", "true");
    this.cols = [
      { field: 'name', header: 'Applicant Name', width: '175px' },
      { field: 'phone', header: 'Phone', width: '125px' },
      { field: 'WhentoCall', header: 'When to Call', width: '160px' },
      { field: 'age', header: 'Age', width: '105px' },
      { field: 'gender', header: 'Gender', width: '125px' },
      { field: 'studyName', header: 'Study Number', width: '175px' },
      { field: 'status', header: 'Status', width: '130px' },
      { field: 'adminid', header: 'Admin ID', width: '125px' }
    ];

    // Load applicant filters from localstore
    this.sessionStore = sessionStorage.getItem("UserPreference_Applicants");
    if (this.sessionStore !== null) {
      this.applicantsFilter = JSON.parse(this.sessionStore);
      this.isFromSessionStore = true;
      this.selectedStudyNumber = this.applicantsFilter.studyNumber;
      this.selectedAge = this.applicantsFilter.age;
      this.selectedGender = this.applicantsFilter.gender;
      this.selectedWhenToCall = this.applicantsFilter.whentocall;
      this.selectedStatus = this.applicantsFilter.status;
    }
    this.getApplicants();
  }
  ngAfterViewInit() { }

  ngOnDestroy() { }

  getAllParticipants() {
    this.getFilters("", "");
    this.participantService.getAllParticipants(this.applicantsFilter)
      .subscribe(_allparticipant => {
        this.allparticipants = _allparticipant;
        this.participantlist = _allparticipant;

        for (let key in this.participantlist) {
          if (this.participantlist.hasOwnProperty(key)) {
            this.participantlist[key].name = this.participantlist[key].lastName + ", " + this.participantlist[key].firstName;
            this.participantlist[key].gender = this.participantlist[key].gender === "M" ? "Male" : "Female";
            this.participantlist[key].WhentoCall = this.participantlist[key].WhentoCall === null ? "" : this.participantlist[key].WhentoCall;
            this.participantlist[key].age = this.participantlist[key].age === null ? 0 : this.participantlist[key].age;
            this.participantlist[key].status = this.participantlist[key].status === null ? "" : this.participantlist[key].status;
          }
        }
      });
  }
  exportAsXLSX(): void {
    this.allparticipantsFinalExport = [];
    var datePipe = new DatePipe('en-US');

    this.participantService.getAllParticipants(this.exportapplicantsFilter)
      .subscribe(_allparticipant => {
        this.allparticipants = _allparticipant;

        for (let i = 0; i < this.allparticipants.length; i++) {
          this.participantObject = new participantExportExcel();
          this.participantObject.StudyName = this.allparticipants[i].studyName;
          this.participantObject.FirstName = this.allparticipants[i].firstName;
          this.participantObject.LastName = this.allparticipants[i].lastName;
          this.participantObject.EmailId = this.allparticipants[i].emailId;
          this.participantObject.Phone = this.allparticipants[i].phone;
          this.participantObject.DOB = datePipe.transform(this.allparticipants[i].dob, 'MM/dd/yyyy');
          this.participantObject.Gender = this.allparticipants[i].gender === "M" ? "Male" : "Female";
          this.participantObject.Height = this.allparticipants[i].height;
          this.participantObject.Weight = this.allparticipants[i].weight;
          this.participantObject.WristCircumference = this.allparticipants[i].wristCircumference;
          this.participantObject.WhentoCall = this.allparticipants[i].WhentoCall;
          this.participantObject.AttemptDate = datePipe.transform(this.allparticipants[i].createdDate, 'MM/dd/yyyy HH:mm');
          this.participantObject.UserStatus = this.allparticipants[i].status;
          if (this.allparticipants[i].adminid === "0" || this.allparticipants[i].adminid === null || this.allparticipants[i].adminid === "") {
            this.participantObject.IsAdmin = "No";
            this.participantObject.AdminID = "";
          }
          else {
            this.participantObject.IsAdmin = "Yes";
            this.participantObject.AdminID = this.allparticipants[i].adminid;
          }

          this.allparticipantsFinalExport.push(this.participantObject);
        }

        this.exportExcel.exportAsExcelFile(this.allparticipantsFinalExport, 'participant-list');
      });
  }

  exportByFilterAsXLSX(): void {
    this.allparticipantsFinalExport = [];
    var datePipe = new DatePipe('en-US');

    if (this.isSearchFilterApplied) {
      this.isSearchFilterApplied = false;
      this.allparticipants = this.tableData.filteredValue;
    }
    else {
      this.allparticipants = this.filteredParticipantlist;
    }

    for (let i = 0; i < this.allparticipants.length; i++) {
      this.participantObject = new participantExportExcel();
      this.participantObject.StudyName = this.allparticipants[i].studyName;
      this.participantObject.FirstName = this.allparticipants[i].firstName;
      this.participantObject.LastName = this.allparticipants[i].lastName;
      this.participantObject.EmailId = this.allparticipants[i].emailId;
      this.participantObject.Phone = this.allparticipants[i].phone;
      this.participantObject.DOB = datePipe.transform(this.allparticipants[i].dob, 'MM/dd/yyyy');
      this.participantObject.Gender = (this.allparticipants[i].gender === "M" || this.allparticipants[i].gender == "Male") ? "Male" : "Female";
      this.participantObject.Height = this.allparticipants[i].height;
      this.participantObject.Weight = this.allparticipants[i].weight;
      this.participantObject.WristCircumference = this.allparticipants[i].wristCircumference;
      this.participantObject.WhentoCall = this.allparticipants[i].WhentoCall;
      this.participantObject.AttemptDate = datePipe.transform(this.allparticipants[i].createdDate, 'MM/dd/yyyy HH:mm');
      this.participantObject.UserStatus = this.allparticipants[i].status;
      if (this.allparticipants[i].adminid === "0" || this.allparticipants[i].adminid === null || this.allparticipants[i].adminid === "") {
        this.participantObject.IsAdmin = "No";
        this.participantObject.AdminID = "";
      }
      else {
        this.participantObject.IsAdmin = "Yes";
        this.participantObject.AdminID = this.allparticipants[i].adminid;
      }
      this.allparticipantsFinalExport.push(this.participantObject);
    }
    this.exportExcel.exportAsExcelFile(this.allparticipantsFinalExport, 'participant-list');
  }

  getFilters(colname, clickType) {
    let applicantsFilterPrimary = new applicantsFilter();
    if (this.applicantFilterHier.length > 0) {
      if (this.applicantFilterHier[0] === "studyName" && (colname !== "studyName" || clickType === "clear")) {
        this.studynumber = [];
        this.participantService.getApplicantsStudynumberFilter(applicantsFilterPrimary)
          .subscribe(response => {
            this.studynumber = [];
            for (let key in response) {
              if (response.hasOwnProperty(key)) {
                this.studynumber.push({ label: response[key].studyName, value: response[key].studyName })
              }
            }
          });
      }
      else if (this.applicantFilterHier[0] === "whentocall" && (colname !== "whentocall" || clickType === "clear")) {
        this.whentocall = [];
        this.participantService.getApplicantsWhentoCallFilter(applicantsFilterPrimary)
          .subscribe(response => {
            this.whentocall = [];
            for (let key in response) {
              if (response.hasOwnProperty(key)) {
                this.whentocall.push({ label: response[key].WhentoCall === null || response[key].WhentoCall === "" ? "(Blank)" : response[key].WhentoCall, value: response[key].WhentoCall })
              }
            }
          });
      }
      else if (this.applicantFilterHier[0] === "age" && (colname !== "age" || clickType === "clear")) {
        this.age = [];
        this.participantService.getApplicantsAgeFilter(applicantsFilterPrimary)
          .subscribe(response => {
            this.age = [];
            for (let key in response) {
              if (response.hasOwnProperty(key)) {
                if (response[key].age !== null)
                  this.age.push({ label: response[key].age === -2 ? '0' : response[key].age.toString(), value: response[key].age })
              }
            }
            this.age = Array.from(this.age.reduce((m, t) => m.set(t.label, t), new Map()).values());
          });
      }
      else if (this.applicantFilterHier[0] === "gender" && (colname !== "gender" || clickType === "clear")) {
        this.gender = [];
        this.gender = [
          { label: 'Female', value: 'F' },
          { label: 'Male', value: 'M' }
        ];
      }
      else if (this.applicantFilterHier[0] === "status" && (colname !== "status" || clickType === "clear")) {
        this.status = [];
        this.participantService.getApplicantsStatusFilter(applicantsFilterPrimary)
          .subscribe(response => {
            this.status = [];
            for (let key in response) {
              if (response.hasOwnProperty(key)) {
                this.status.push({ label: response[key].status, value: response[key].status })
              }
            }
          });
      }

      if (this.applicantsFilter.age.includes(0) && !this.applicantsFilter.age.includes(null))
        this.applicantsFilter.age.push(null);

      if (this.applicantFilterHier[0] !== "whentocall" && (colname !== "whentocall" || clickType === "clear")) {
        this.participantService.getApplicantsWhentoCallFilter(this.applicantsFilter)
          .subscribe(response => {
            this.whentocall = [];
            for (let key in response) {
              if (response.hasOwnProperty(key)) {
                this.whentocall.push({ label: response[key].WhentoCall === null || response[key].WhentoCall === "" ? "(Blank)" : response[key].WhentoCall, value: response[key].WhentoCall })
              }
            }
          });
      }
      if (this.applicantFilterHier[0] !== "age" && (colname !== "age" || clickType === "clear")) {
        this.participantService.getApplicantsAgeFilter(this.applicantsFilter)
          .subscribe(response => {
            this.age = [];
            for (let key in response) {
              if (response.hasOwnProperty(key)) {
                if (response[key].age !== null)
                  this.age.push({ label: response[key].age === -2 ? '0' : response[key].age.toString(), value: response[key].age })
              }
            }
            this.age = Array.from(this.age.reduce((m, t) => m.set(t.label, t), new Map()).values());
          });
      }
      if (this.applicantFilterHier[0] !== "gender" && (colname !== "gender" || clickType === "clear")) {
        this.gender = [];
        this.gender = [
          { label: 'Female', value: 'F' },
          { label: 'Male', value: 'M' }
        ];
      }
      if (this.applicantFilterHier[0] !== "studyName" && (colname !== "studyName" || clickType === "clear")) {
        this.participantService.getApplicantsStudynumberFilter(this.applicantsFilter)
          .subscribe(response => {
            this.studynumber = [];
            for (let key in response) {
              if (response.hasOwnProperty(key)) {
                this.studynumber.push({ label: response[key].studyName, value: response[key].studyName })
              }
            }
          });
      }
      if (this.applicantFilterHier[0] !== "status" && (colname !== "status" || clickType === "clear")) {
        this.participantService.getApplicantsStatusFilter(this.applicantsFilter)
          .subscribe(response => {
            this.status = [];
            for (let key in response) {
              if (response.hasOwnProperty(key)) {
                this.status.push({ label: response[key].status, value: response[key].status })
              }
            }
          });
      }
    }
    else {

      this.applicantsFilter = new applicantsFilter();

      this.gender = [
        { label: 'Female', value: 'F' },
        { label: 'Male', value: 'M' }
      ];
      this.participantService.getApplicantsWhentoCallFilter(this.applicantsFilter)
        .subscribe(response => {
          this.whentocall = [];
          for (let key in response) {
            if (response.hasOwnProperty(key)) {
              this.whentocall.push({ label: response[key].WhentoCall === null || response[key].WhentoCall === "" ? "(Blank)" : response[key].WhentoCall, value: response[key].WhentoCall })
            }
          }
        });
      this.participantService.getApplicantsAgeFilter(this.applicantsFilter)
        .subscribe(response => {
          this.age = [];
          for (let key in response) {
            if (response.hasOwnProperty(key)) {
              if (response[key].age != null)
                this.age.push({ label: response[key].age === -2 ? '0' : response[key].age.toString(), value: response[key].age })
            }
          }
          this.age = Array.from(this.age.reduce((m, t) => m.set(t.label, t), new Map()).values());
        });
      this.participantService.getApplicantsStudynumberFilter(this.applicantsFilter)
        .subscribe(response => {
          this.studynumber = [];
          for (let key in response) {
            if (response.hasOwnProperty(key)) {
              this.studynumber.push({ label: response[key].studyName, value: response[key].studyName })
            }
          }
        });
      this.participantService.getApplicantsStatusFilter(this.applicantsFilter)
        .subscribe(response => {
          this.status = [];
          for (let key in response) {
            if (response.hasOwnProperty(key)) {
              this.status.push({ label: response[key].status === null ? '(Blank)' : response[key].status, value: response[key].status })
            }
          }
        });
    }

    this.filterApplicants(colname);
  }

  /* Clear Study Column */
  clearGridColumn(event, table, colname) {
    this.isClearFilterOn = true;
    switch (colname) {
      case "studyName":
        this.checkedStudyNumber = [];
        this.applicantsFilter.studyNumber = [];
        break;
      case "whentocall":
        this.checkedWhenToCall = [];
        this.applicantsFilter.whentocall = [];
        break;
      case "age":
        this.checkedAge = [];
        this.applicantsFilter.age = [];
        break;
      case "gender":
        this.checkedGender = [];
        this.applicantsFilter.gender = [];
        break;
      case "status":
        this.checkedStatus = [];
        this.applicantsFilter.status = [];
        break;
    }
    this.applicantFilterHier = this.applicantFilterHier.filter(x => x !== colname);
    this.clearPrescreenFilter(colname);
    table.filter('', colname, 'contains');
    sessionStorage.setItem("UserPreference_Applicants", JSON.stringify(this.applicantsFilter));
  }
  getApplicants() {
    if (this.applicantsFilter.age.includes(0) && !this.applicantsFilter.age.includes(null))
      this.applicantsFilter.age.push(null);

    this.participantService.getAllParticipants(this.applicantsFilter)
      .subscribe(response => {
        this.participantlist = response;
        this.filteredParticipantlist = response;
        this.getFilters("", "");
      });
  }
  filterGridRecords(event, table, colname) {
    this.isFromSessionStore = false;
    if (!this.applicantFilterHier.includes(colname))
      this.applicantFilterHier.push(colname);

    switch (colname) {
      case "whentocall":
        this.clearwhentocall.forEach(ds => {
          if(ds.value !== null) {
            this.applicantsFilter.whentocall = ds.value;
          }
          this.applicantsFilter.whentocall.concat(this.selectedWhenToCall);
          ds.overlayVisible = false;
        });
        break;
      case "studyName":
        this.clearStudy.forEach(ds => {
          if(ds.value !== null) {
            this.applicantsFilter.studyNumber = ds.value;
          }
          this.applicantsFilter.studyNumber.concat(this.selectedStudyNumber);
          ds.overlayVisible = false;
        });
        break;
      case "age":
        this.clearage.forEach(ds => {
          if(ds.value !== null) {
            this.applicantsFilter.age = ds.value;
          }
          this.applicantsFilter.age.concat(this.selectedAge);
          ds.overlayVisible = false;
        });
        break;
      case "gender":
        this.cleargender.forEach(ds => {
          if(ds.value !== null) {
            this.applicantsFilter.gender = ds.value;
          }
          this.applicantsFilter.gender.concat(this.selectedGender);
          ds.overlayVisible = false;
        });
        break;
      case "status":
        this.clearstatus.forEach(ds => {
          if(ds.value !== null) {
            this.applicantsFilter.status = ds.value;
          }
          this.applicantsFilter.status.concat(this.selectedStatus);
          ds.overlayVisible = false;
        });
        break;
    }

    if (this.applicantsFilter.age.includes(0) && !this.applicantsFilter.age.includes(null))
      this.applicantsFilter.age.push(null);

    this.participantService.getAllParticipants(this.applicantsFilter)
      .subscribe(response => {
        this.participantlist = response;
        this.filteredParticipantlist = response;
        this.getFilters(colname, "ok");
      });
    sessionStorage.setItem("UserPreference_Applicants", JSON.stringify(this.applicantsFilter));
    this.sessionStore = sessionStorage.getItem("UserPreference_Applicants");
  }

  clearPrescreenFilter(colname) {

    if (this.applicantsFilter.age.includes(0) && !this.applicantsFilter.age.includes(null))
      this.applicantsFilter.age.push(null);

    this.participantService.getAllParticipants(this.applicantsFilter)
      .subscribe(response => {
        this.participantlist = response;
        this.filteredParticipantlist = response;
        this.getFilters(colname, "clear");
        //this.filterApplicants(colname);
      });
  }

  filterApplicants(filterFor) {
    for (let key in this.filteredParticipantlist) {
      if (this.filteredParticipantlist.hasOwnProperty(key)) {
        this.filteredParticipantlist[key].name = this.filteredParticipantlist[key].lastName + ", " + this.filteredParticipantlist[key].firstName;
        this.filteredParticipantlist[key].gender = this.filteredParticipantlist[key].gender === "M" ? "Male" : this.filteredParticipantlist[key].gender === "F" ? "Female" : this.filteredParticipantlist[key].gender;
        this.filteredParticipantlist[key].age = this.filteredParticipantlist[key].age === null ? -2 : this.filteredParticipantlist[key].age;
        this.filteredParticipantlist[key].createdDate = this.filteredParticipantlist[key].createdDate === null ? "" : this.filteredParticipantlist[key].createdDate;
      }
    }

    if(!this.isClearFilterOn) {
      if (this.isFromSessionStore) {
        this.checkedStudyNumber = this.selectedStudyNumber;
        this.checkedWhenToCall = this.selectedWhenToCall;
        this.checkedAge = this.selectedAge;
        this.checkedGender = this.selectedGender;
        this.checkedStatus = this.selectedStatus;
      } else {
        if (this.isFirstNavigation || this.sessionStore === null) {
          this.checkedStudyNumber = this.filteredParticipantlist.map(x => x.studyName)
            .filter((value, index, self) => self.indexOf(value) === index);
          this.checkedWhenToCall = this.filteredParticipantlist.map(x => x.WhentoCall)
            .filter((value, index, self) => self.indexOf(value) === index);
          this.checkedAge = this.filteredParticipantlist.map(x => x.age)
            .filter((value, index, self) => self.indexOf(value) === index);
          this.checkedGender = this.gender.map(x => x.value);
          this.checkedStatus = this.filteredParticipantlist.map(x => x.status)
            .filter((value, index, self) => self.indexOf(value) === index);
          
        } else {
          this.checkedStudyNumber = this.applicantsFilter.studyNumber;
          this.checkedWhenToCall = this.applicantsFilter.whentocall;
          this.checkedAge = this.applicantsFilter.age;
          this.checkedGender = this.applicantsFilter.gender;
          this.checkedStatus = this.applicantsFilter.status;
        }
      }
    }
    this.isClearFilterOn = false;
    this.isFirstNavigation = false;
  }
  paginate(event) {
    this.scrollTop.nativeElement.focus();
  }

  checkAllPropertiesEmpty(): boolean {
    if (this.isSearchFilterApplied) {
      return false;
    }
    const properties = Object.values(this.applicantsFilter);
    return properties.every(property => Array.isArray(property) && property.length === 0);
  }

  onRowSelect() {
    this.updateDeleteButtonState();
  }

  onRowUnselect() {
    this.updateDeleteButtonState();
  }

  onHeaderCheckboxToggle() {
    this.updateDeleteButtonState();
  }

  updateDeleteButtonState() {
    this.isDeleteEnabled = this.selectedApplicants.length > 0;
  }

  deleteSelectedApplicants() {
    const selectedApplicantIds = this.selectedApplicants.map(par => par.participantId).join(',');
    this.participantService.deleteParticipants(selectedApplicantIds).subscribe(res => {
      this.getApplicants();
      this.selectedApplicants = [];
      this.updateDeleteButtonState();
      alert('Your selection(s) have been deleted');
    })
  }

  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteSelectedApplicants();
      },
      reject: () => {
        this.updateDeleteButtonState();
      }
    });
  }
}
