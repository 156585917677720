import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminHomeComponent } from './Study/admin_home/admin_home.component';
import { AdminStudyDetailComponent } from './Study/admin_study_detail/admin_study_detail.component';
import { AdminDemographicsComponent } from './admin_demographic_list/admin_demographic_list.component';
import { AdminPreScreenerListComponent } from './pre-screening-questions/admin_prescreener_list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DataTablesModule } from 'angular-datatables';
import { AdminPrescreenerComponent } from './pre-screening-questions/admin_prescreener/admin_prescreener.component';
import { AdminParticipantListComponent } from './admin-participant-list/admin-participant-list.component';
import { AdminParticipantAnswersComponent } from './admin-participant-answers/admin-participant-answers.component';
import { AdminReferralComponent } from './admin_referral/admin_referral.component';
import { AdminParticipantAttemptComponent } from './admin-participant-attempt/admin-participant-attempt.component';
import { Router } from '@angular/router';
import { ConfigService } from 'src/services/config.service';
import { StudyListResolverService } from 'src/services/study-list-resolver.service';
import { AdminNotFoundComponent } from './admin-not-found/admin-not-found.component';
import { RoundPipe } from './round.pipe';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputMaskModule } from 'primeng/inputmask';
import { ConfirmationService } from 'primeng/api';
import { ChipsModule } from 'primeng/chips';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogModule } from 'primeng/dialog';
import { SpinnerModule } from 'primeng/spinner';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from "@mat-datetimepicker/core";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { QuestionLibraryComponent } from './question-library/question-library.component';
import { CreatePrescreeningQuestionComponent } from './create-prescreening-question/create-prescreening-question.component';
import { PaginatorModule } from 'primeng/paginator';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { library as fontLibrary } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faClock } from '@fortawesome/free-regular-svg-icons';
import { CKEditorModule } from 'ng2-ckeditor';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MatSelectModule } from '@angular/material/select';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RestrictedCharactersValidatorDirective } from 'src/directives/restricted-characters.directive';

fontLibrary.add(
  faCalendar,
  faClock
);


export const MY_MOMENT_FORMATS = {
  parseInput: 'MM-DD-YYYY HH:mm',
  fullPickerInput: 'MM-DD-YYYY HH:mm',
  datePickerInput: 'MM-DD-YYYY',
  timePickerInput: 'HH:mm:ss',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [
    AdminHomeComponent,
    AdminStudyDetailComponent,
    AdminDemographicsComponent,
    AdminPreScreenerListComponent,
    AdminPrescreenerComponent,
    AdminParticipantListComponent,
    AdminParticipantAnswersComponent,
    AdminReferralComponent,
    AdminParticipantAttemptComponent,
    AdminNotFoundComponent,
    RoundPipe,
    QuestionLibraryComponent,
    CreatePrescreeningQuestionComponent,
    RestrictedCharactersValidatorDirective
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule, 
    DataTablesModule,
    NgMultiSelectDropDownModule,
    TableModule,
    MultiSelectModule,
    InputMaskModule,
    AutoCompleteModule,
    InputTextModule,
    ChipsModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    DialogModule,
    SpinnerModule,
    InputSwitchModule,
    MatExpansionModule,
    DragDropModule,
    MatDatepickerModule,
    MatNativeDatetimeModule,
    MatDatetimepickerModule,
    BsDropdownModule,
    BsDatepickerModule,
    TimepickerModule,
    PaginatorModule,
    NgbModule,
    CKEditorModule,
    ButtonModule,
    CalendarModule,
    ConfirmDialogModule
  ],
  providers:[
    StudyListResolverService,
    ConfirmationService
  ]
})
export class AdminModule implements OnInit{ 
constructor(private configService: ConfigService, private router: Router){}
userId:string;
  ngOnInit() { 
}
}
