import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from 'src/services/admin.service';
import { questionModel, answersModel } from 'src/models/question.model';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { questionstudyModel, studyQuestionModel } from 'src/models/questionstudy.model';
import { Router } from '@angular/router';
import { ConfigService } from 'src/services/config.service';

@Component({
  selector: 'app-admin_prescreener',
  templateUrl: './admin_prescreener.component.html',
  styleUrls: ['./admin_prescreener.component.css']
})
export class AdminPrescreenerComponent implements OnInit {


  frmGrpAns: FormGroup;
  frmAryAns: FormArray;
  isEdit: boolean;
  answerType: boolean = true;
  textAnsId: number;
  textAns: string;
  template: string;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  private _paramsStudyId: number;
  private _paramQuestionId: number;

  public get paramsStudyId(): number {
    return isNaN(this._paramsStudyId) ? 0 : this._paramsStudyId;
  }
  public set paramsStudyId(v: number) {
    this._paramsStudyId = v;
  }

  public get paramQuestionId(): number {
    return isNaN(this._paramQuestionId) ? 0 : this._paramQuestionId;
  }
  public set paramQuestionId(v: number) {
    this._paramQuestionId = v;
  }

  public get questionCategory(): string {
    return "PreScreener";
  }
  public get Answers(): FormArray {
    return (<FormArray>this.frmGrpAns.get('frmAryAns'));
  }
  public get QuestionModel(): questionModel {
    return this.questionmodel
  }

  public questionmodel: questionModel;
  public qsModel: questionstudyModel;
  constructor(private route: ActivatedRoute, private questionService: AdminService,
    private fb: FormBuilder, private router: Router, private configService: ConfigService) {

    this.questionmodel = new questionModel();
    this.qsModel = new questionstudyModel();
    this.qsModel.question = new questionModel();
    this.qsModel.studyQuestion = new studyQuestionModel();
    this.qsModel.answer = [];

    this.isEdit = false;
  }
  userId: string;
  ngOnInit() {
    this.frmGrpAns = this.fb.group({
      frmAryAns: this.fb.array([this.addAnsFormGroup(0, "", "Disqualified")])
    });

    this.route.paramMap.subscribe(params => {
      this.paramsStudyId = parseInt(params.get('studyId'));
      this.paramQuestionId = parseInt(params.get('questionId'));
    });
    if (this.paramQuestionId > 0 && this.paramsStudyId > 0) {
      this.template = "Edit";
      // this.editQuestion();
      this.getqsByQuestionId(this.questionCategory, this.paramQuestionId, this.paramsStudyId);
    }
    else if (this.paramQuestionId > 0) {
      this.template = "Edit";
      this.getQuestion();
    }
    else {
      this.template = "Add";
    }

    this.multiSelectddlngOnInit();
    this.getAvailableStudies();

  }
  preventPipe(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 124) {
      return false;
    }
    return true;
  }

  multiSelectddlngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'studyId',
      textField: 'studyName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  addAnsFormGroup(answerId: number, answer: string, correctAnswer: string): FormGroup {
    return this.fb.group({
      answerId: [answerId, Validators.required],
      Answer: [answer, Validators.required],
      correctAnswer: [correctAnswer, Validators.required]
    })

  }

  btnClickAddAns(): void {
    this.Answers.push(this.addAnsFormGroup(0, "", "Disqualified"));
  }

  btnClickRemoveAns(rowIndex: number): void {
    if ((this.Answers.length) > 1) {
      this.Answers.removeAt(rowIndex);
    }
  }

  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }

  getAvailableStudies() {
    this.questionService.getAllStudyNames()
      .subscribe(res => {
        this.dropdownList = res;
        if (this.paramsStudyId > 0) {
          let f = this.dropdownList.find(x => x.studyId == this.paramsStudyId);
          this.selectedItems = [{ studyId: f.studyId, studyName: f.studyName }];
        }
      }, error => {
        this.router.navigate(['adminhome/notfound']);
      });
  }
  getQuestion() {
    this.questionService.getQuestionById(this.paramQuestionId)
      .subscribe(r => { this.questionmodel = r }, error => {
        this.router.navigate(['adminhome/notfound'])
      });
  }
  getqsByQuestionId(qCategory: string, questionId?: number, studyId?: number) {
    this.questionService.getQuestionStudyAnswers(qCategory, questionId, studyId)
      .subscribe(res => {
        this.qsModel = res[0];//response[0];
        if (this.qsModel == undefined) {
          this.router.navigate(['adminhome/notfound']);
          return;
        }

        this.questionmodel.questionId = this.qsModel.question.questionId;
        this.questionmodel.questionText = this.qsModel.question.questionText;
        this.answerType = this.qsModel.question.answerType == "Radio" ? true : false;
        if (this.answerType) {
          this.Answers.removeAt(0);
          this.qsModel.answer.forEach((v, i) => {
            this.Answers.push(
              this.addAnsFormGroup(v.answerId, v.answerText, v.isCorrect ? "Qualified" : "Disqualified"));
          });
        }
        else {
          this.textAnsId = this.qsModel.answer[0].answerId;
          this.textAns = this.qsModel.answer[0].answerText;
        }
      }, error => {
        this.router.navigate(['adminhome/notfound']);
      });
  }
  addQuestion() {

    this.questionService.postQuestion(this.questionmodel)
      .subscribe(res => {
        if (confirm('Question Added Successfully'))
          this.router.navigateByUrl("adminhome/Prescreener");
      });
  }
  addQuestionStudy() {
    this.questionService.postQuestionStudy(this.qsModel)
      .subscribe(res => { });
  }
  updateQuestionStudy() {
    this.questionService.postUpdateQS(this.qsModel)
      .subscribe(res => {
      });
  }
  updateQuestion() {

    this.questionService.updateQuestion(this.questionmodel)
      .subscribe(res => {
        if (confirm('Question Updated Successfully'))
          this.router.navigateByUrl("adminhome/Prescreener");
      });
  }
  getqsModel() {
    this.qsModel.question.questionText = this.questionmodel.questionText.trim();
    this.qsModel.question.toolTip = "NA";
    this.qsModel.question.answerType = this.answerType ? "Radio" : "Text";
    this.qsModel.question.questionCategory = this.questionCategory;
    this.qsModel.studyQuestion.studyId = this.paramsStudyId;
    this.qsModel.studyQuestion.questionOrder = 1;
    this.qsModel.studyQuestion.isPrescreener = true;
    this.qsModel.studyQuestion.isActive = true;

    if (this.answerType) {
      this.qsModel.answer = [];
      for (let cntrl of this.frmGrpAns.value.frmAryAns) {
        if (cntrl.Answer.trim() != "" && cntrl.Answer != undefined)
          this.qsModel.answer.push({
            answerId: cntrl.answerId,
            answerText: cntrl.Answer.trim(),
            isCorrect: cntrl.correctAnswer == "Qualified" ? true : false,
            isActive: true
          });
      }
    }
    else {
      if (this.textAns != undefined && this.textAns != null && this.textAns.trim() != "") {
        this.qsModel.answer = [];
        this.qsModel.answer.push({
          answerId: isNaN(this.textAnsId) ? 0 : this.textAnsId, answerText: this.textAns, isCorrect: false, isActive: true
        });
      }
    }
  }
  btnAction() {
    this.questionmodel.questionCategory = this.questionCategory;
    this.questionmodel.questionDesc = this.questionmodel.questionText;
    this.questionmodel.toolTip = "";
    this.getqsModel();

    if (this.questionmodel.questionText == undefined
      || this.questionmodel.questionText == null
      || this.questionmodel.questionText.trim() == "") {
      alert('No question found!');
      return;
    }
    if (this.selectedItems.length > 0) {
      if (this.answerType) {
        if (this.qsModel.answer.length <= 0) {
          alert('Answer is required!')
          return;
        }
        else {
          let isInvalidAnswer = false;
          for (let cntrl of this.frmGrpAns.value.frmAryAns) {
            if (cntrl.Answer.trim() == "" || cntrl.Answer == undefined) {
              isInvalidAnswer = true;
            }
          }
          if (isInvalidAnswer) {
            // alert('Answer should not empty!');
            alert('Answer is required!');
            return;
          }
        }
      }
      else if (this.textAns == undefined || this.textAns == null || this.textAns.trim() == "") {
        alert('Answer is required!');
        return;
      }
    }

    if (this.selectedItems.length <= 0) {
      // adminhome -> PreScreener Tab Add click -- /adminhome/addnewpre/0 
      if (this.paramQuestionId <= 0) { //this.paramsStudyId <= 0 &&
        this.addQuestion();
      }
      // adminhome -> PreScreener Tab Edit click -- /adminhome/addnewpre/0/42
      else if (this.paramQuestionId > 0) { //this.paramsStudyId <= 0 &&
        this.updateQuestion();
      }
    }
    else {
      // adminhome -> Edit Study -> PreScreener Tab Add click -- /adminhome/studydetail/25
      if ((this.selectedItems.length > 0 || this.paramsStudyId > 0) && this.paramQuestionId <= 0) {
        this.selectedItems.forEach(s => {
          this.qsModel.studyQuestion.studyId = s.studyId;
          this.addQuestionStudy();
        });
        if (confirm('Question and Answer Added Successfully'))
          this.router.navigateByUrl("adminhome");
      }
      // adminhome -> Edit Study -> PreScreener Tab Edit click -- /adminhome/studydetail/25/29
      else if ((this.selectedItems.length > 0 || this.paramsStudyId > 0) && this.paramQuestionId > 0) {
        this.qsModel.question.questionId = this.paramQuestionId;
        this.selectedItems.forEach(s => {
          this.qsModel.studyQuestion.studyId = s.studyId;
          this.updateQuestionStudy();
        });
        if (confirm('Question and Answer Updated Successfully'))
          this.router.navigateByUrl("adminhome");
      }
    }
  }
}
