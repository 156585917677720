import { Component, OnInit, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { questionModel } from 'src/models/question.model';
import { questionstudyModel, studyQuestionModel } from 'src/models/questionstudy.model';
import { AdminService } from 'src/services/admin.service';
import { ConfigService } from 'src/services/config.service';
import { EventEmitter } from '@angular/core';
import { sessionData } from 'src/models/data.model';
import { DataService } from 'src/services/data.service';

@Component({
  selector: 'app-create-prescreening-question',
  templateUrl: './create-prescreening-question.component.html',
  styleUrls: ['./create-prescreening-question.component.css']
})
export class CreatePrescreeningQuestionComponent implements OnInit {
  @Output() messageToParent = new EventEmitter<string>();
  frmGrpAns: FormGroup;
  frmAryAns: FormArray;

  isEdit: boolean;
  answerType: boolean = true;
  textAnsId: number;
  textAns: string;
  template: string;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  includeFreeText: boolean = false;
  includePredefined: boolean = false;
  private _paramsStudyId: number;
  private _paramQuestionId: number;
  isSaveAnother: boolean = false;
  public sessionData: sessionData;

  @Input() studyId1: number = 0;
  @Input() data: number = 0;
  @Input() questionIndex: number=0;

  public __studyId: number = 0;

  public get paramsStudyId(): number {
    return isNaN(this._paramsStudyId) ? 0 : this._paramsStudyId;
  }
  public set paramsStudyId(v: number) {
    this._paramsStudyId = v;
  }

  public get paramQuestionId(): number {
    return isNaN(this._paramQuestionId) ? 0 : this._paramQuestionId;
  }
  public set paramQuestionId(v: number) {
    this._paramQuestionId = v;
  }

  public get questionCategory(): string {
    return "PreScreener";
  }
  public get Answers(): FormArray {
    return (<FormArray>this.frmGrpAns.get('frmAryAns'));
  }
  public get QuestionModel(): questionModel {
    return this.questionmodel
  }

  public questionmodel: questionModel;
  public qsModel: questionstudyModel;
  constructor(private route: ActivatedRoute, private questionService: AdminService,
    private fb: FormBuilder, private router: Router, private configService: ConfigService, private activateRoute: ActivatedRoute, private dataService: DataService) {
    this.dataService.onUnload = true;
    this.questionmodel = new questionModel();
    this.qsModel = new questionstudyModel();
    this.qsModel.question = new questionModel();
    this.qsModel.studyQuestion = new studyQuestionModel();
    this.qsModel.answer = [];

    this.isEdit = false;
  }
  userId: string;
  ngOnInit() {
    this.__studyId = this.data;
    this.route.paramMap.subscribe(params => {
      this.paramsStudyId = parseInt(params.get('studyId'));
    });

    this.activateRoute.paramMap.subscribe(params => {      
      this.paramsStudyId = parseInt(params.get('id'));
      //if (this.studyid > 0) {
      //  this._studyid = this.studyid;
      //}
    });
    if (this.paramsStudyId == null || this.paramsStudyId == undefined || this.paramsStudyId == 0 || this.paramsStudyId.toString() == '' || this.paramsStudyId.toString() == 'NaN') {
      this.sessionData = this.dataService.getData();
      this.paramsStudyId = this.sessionData.studyId;
    }

    this.frmGrpAns = this.fb.group({
      frmAryAns: this.fb.array([this.addAnsFormGroup(0, "Yes", "Disqualified", false), this.addAnsFormGroup(0, "No", "Disqualified", false)])
    });

    //this.route.paramMap.subscribe(params => {
    //  this.paramsStudyId = parseInt(params.get('studyId'));
    //  this.paramQuestionId = parseInt(params.get('questionId'));
    //});
    this.template = "Add";
  }
  preventPipe(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 124) {
      return false;
    }
    return true;
  }


  addAnsFormGroup(answerId: number, answer: string, correctAnswer: string, disqualify: boolean): FormGroup {
    return this.fb.group({
      answerId: [answerId, Validators.required],
      Answer: [answer, Validators.required],
      correctAnswer: [correctAnswer, Validators.required],
      disqualify: [disqualify, Validators.required]
    })

  }
  btnClickAddAns(): void {
    this.Answers.push(this.addAnsFormGroup(0, "", "Disqualified", false));
  }
  btnClickRemoveAns(rowIndex: number): void {
    if ((this.Answers.length) > 1) {
      this.Answers.removeAt(rowIndex);
    }
  }

  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }

  addQuestionStudy() {
    
    this.questionService.postQuestionStudy(this.qsModel)
      .subscribe(res => {
        if (res == '-1') {
          alert('Question already exists');
        }
        else {
          if (this.isSaveAnother) {
            alert('Question and Answer Added Successfully');
            this.questionmodel = new questionModel();
            this.qsModel = new questionstudyModel();
            this.qsModel.question = new questionModel();
            this.qsModel.studyQuestion = new studyQuestionModel();
            this.qsModel.answer = [];
            this.includeFreeText = false;
            this.includePredefined = false;
            this.frmGrpAns = this.fb.group({
              frmAryAns: this.fb.array([this.addAnsFormGroup(0, "Yes", "Disqualified", false), this.addAnsFormGroup(0, "No", "Disqualified", false)])
            });
          }
          else {
            alert('Question and Answer Added Successfully');
            this.messageToParent.emit("true");
            //this.router.navigateByUrl("Prescreener");
          }
        }
      });
  }
  getqsModel() {
   
    this.qsModel.question.questionText = this.questionmodel.questionText.trim();
    this.qsModel.question.toolTip = "NA";
    this.qsModel.question.includeFreeText = this.includeFreeText;
    this.qsModel.question.includePredefined = this.includePredefined;
    if (this.includeFreeText && this.includePredefined) {
      this.qsModel.question.answerType = "Radio-Text";
    } else if (this.includeFreeText) {
      this.qsModel.question.answerType = "Text";
    } else if (this.includePredefined) {
      this.qsModel.question.answerType = "Radio";
    }
    this.qsModel.question.questionCategory = this.questionCategory;
    this.qsModel.studyQuestion.studyId = this.paramsStudyId;
    this.qsModel.studyQuestion.questionOrder = (this.questionIndex == 0) ? 0: this.questionIndex + 1;
    this.qsModel.studyQuestion.isPrescreener = true;
    this.qsModel.studyQuestion.isActive = true;
    this.qsModel.studyQuestion.includeFreeText = this.includeFreeText;
    this.qsModel.studyQuestion.includePredefined = this.includePredefined;

    this.qsModel.answer = [];
    for (let cntrl of this.frmGrpAns.value.frmAryAns) {
      if (cntrl.Answer.trim() != "" && cntrl.Answer != undefined)
        this.qsModel.answer.push({
          answerId: cntrl.answerId,
          answerText: cntrl.Answer.trim(),
          isCorrect: !cntrl.disqualify,
          isActive: true
          
        });
    }
  }
  btnAction() {    
    if (this.paramsStudyId == null || this.paramsStudyId == undefined || this.paramsStudyId == 0 || this.paramsStudyId.toString() == '' || this.paramsStudyId.toString() == 'NaN') {
      this.sessionData = this.dataService.getData();
      this.paramsStudyId = this.sessionData.studyId;
    }
    if (this.paramsStudyId == 0) {
      alert("Study not created. Study details required.");
      return false;
    }
    if (this.paramsStudyId == null) {
      alert("Study not created. Study details required.");
      //this.router.navigate(['/adminhome/studydetail/'], { fragment: 'display', skipLocationChange: true });
      return false;
    }
    if (this.paramsStudyId.toString() == "" || this.paramsStudyId.toString() == 'NaN') {
      alert("Study not created. Study details required.");
      return false;
    }

    this.questionmodel.questionCategory = this.questionCategory;
    this.questionmodel.questionDesc = this.questionmodel.questionText;
    this.questionmodel.toolTip = "";
    this.getqsModel();

    if (this.questionmodel.questionText == undefined
      || this.questionmodel.questionText == null
      || this.questionmodel.questionText.trim() == "") {
      alert('No question found!');
      return;
    }   

    if (!this.includeFreeText && !this.includePredefined) {
      alert('Atleast one question option needs to be chosen!');
      return false;
    }
    
    if (this.qsModel.answer.length == 0) {
      alert('Answer is required!')
      return;
    }
    else {
      let isInvalidAnswer = false;
      let isDisqualifyReq = false;

      for (let cntrl of this.frmGrpAns.value.frmAryAns) {
        if (cntrl.Answer.trim() == "" || cntrl.Answer == undefined) {
          isInvalidAnswer = true;
        } if (cntrl.disqualify == false ) {
          isDisqualifyReq = true;
        }
      }

      if (isInvalidAnswer) {
        alert('Answer is required!');
        return;
      }
      if (!isDisqualifyReq) {
        alert('Atleast one option should be Qualify!');
        return;
      }
    }        
    this.addQuestionStudy();
  }
  btnSave() {
    this.isSaveAnother = false;
    this.btnAction();
  }
  btnSaveAnother() {
    this.isSaveAnother = true;
    this.btnAction();
  }
  btnCancel() {
    this.isSaveAnother = false;
    this.messageToParent.emit("true");
  }
  onIncludeFreeText(event) {
    this.includeFreeText = event.target.checked;
  }
  onIncludePredefinedOptions(event) {
    this.includePredefined = event.target.checked;
  }

  getResponseFromChild(response: string) {
    this.messageToParent.emit("true");
  }
}
