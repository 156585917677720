// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ansLabel {
  display: inline-block;
  margin-right: 50px;
}
.removeQues {
  padding: 0;
  font-size: 26px;
  line-height: 55px;
  position: absolute;
  right: 10px;
  z-index: 2;
}
.ansWrap {
  padding: 0 5px;
  display: inline-block;
}
.addansBtn {
  height: 40px!important;
  padding-left: 16px; 
}
.btn-prim {
  box-shadow: 0 5px 7px 0px rgba(0,0,0,0.07);
  height: 32px;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/create-prescreening-question/create-prescreening-question.component.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,UAAU;AACZ;AACA;EACE,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;AACA;EACE,0CAA0C;EAC1C,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".ansLabel {\r\n  display: inline-block;\r\n  margin-right: 50px;\r\n}\r\n.removeQues {\r\n  padding: 0;\r\n  font-size: 26px;\r\n  line-height: 55px;\r\n  position: absolute;\r\n  right: 10px;\r\n  z-index: 2;\r\n}\r\n.ansWrap {\r\n  padding: 0 5px;\r\n  display: inline-block;\r\n}\r\n.addansBtn {\r\n  height: 40px!important;\r\n  padding-left: 16px; \r\n}\r\n.btn-prim {\r\n  box-shadow: 0 5px 7px 0px rgba(0,0,0,0.07);\r\n  height: 32px;\r\n  font-size: 14px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
