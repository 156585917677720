// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.studyTable.table thead>tr>th {
    padding: 10px;
}

.active {
  width: 9px;
  height: 9px;
  border-radius: 700px;
  background-color: green;
  display: inline-block;
}

.inactive {
  width: 9px;
  height: 9px;
  border-radius: 700px;
  background-color: gray;
  display: inline-block;
}
.tagName, .studyNameList, .questionName {
  word-break: break-all;
}

.tableGridWrap .btn-link {
  padding-left: 0 !important;
}

.p-multiselect .p-multiselect-label {
  display: none!important;
}

button[name=exportBtn] {
  background: transparent !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

:host ::ng-deep button {
  margin-right: .25em;
}

.icon-enabled {
  opacity: 1;
  cursor: pointer;
  margin-right: 10px;
}
 
.icon-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  margin-right: 10px;
}

.expBtnPos {
  height: 30px;
  left: 20px;
}

::ng-deep p-multiselect ul,
::ng-deep p-multiselect ol {
  padding-left: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/Study/admin_home/admin_home.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,oBAAoB;EACpB,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,oBAAoB;EACpB,sBAAsB;EACtB,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,UAAU;AACZ;;AAEA;;EAEE,0BAA0B;AAC5B","sourcesContent":[".studyTable.table thead>tr>th {\r\n    padding: 10px;\r\n}\r\n\r\n.active {\r\n  width: 9px;\r\n  height: 9px;\r\n  border-radius: 700px;\r\n  background-color: green;\r\n  display: inline-block;\r\n}\r\n\r\n.inactive {\r\n  width: 9px;\r\n  height: 9px;\r\n  border-radius: 700px;\r\n  background-color: gray;\r\n  display: inline-block;\r\n}\r\n.tagName, .studyNameList, .questionName {\r\n  word-break: break-all;\r\n}\r\n\r\n.tableGridWrap .btn-link {\r\n  padding-left: 0 !important;\r\n}\r\n\r\n.p-multiselect .p-multiselect-label {\r\n  display: none!important;\r\n}\r\n\r\nbutton[name=exportBtn] {\r\n  background: transparent !important;\r\n}\r\n\r\n.disabled {\r\n  pointer-events: none;\r\n  opacity: 0.5;\r\n}\r\n\r\n:host ::ng-deep button {\r\n  margin-right: .25em;\r\n}\r\n\r\n.icon-enabled {\r\n  opacity: 1;\r\n  cursor: pointer;\r\n  margin-right: 10px;\r\n}\r\n \r\n.icon-disabled {\r\n  opacity: 0.5;\r\n  cursor: not-allowed;\r\n  margin-right: 10px;\r\n}\r\n\r\n.expBtnPos {\r\n  height: 30px;\r\n  left: 20px;\r\n}\r\n\r\n::ng-deep p-multiselect ul,\r\n::ng-deep p-multiselect ol {\r\n  padding-left: 0 !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
