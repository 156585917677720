import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appRestrictedCharacters]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RestrictedCharactersValidatorDirective, multi: true }]
})
export class RestrictedCharactersValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const forbiddenChars = /[?/:\\[\]*]/;
    const value = control.value;
    if (value && forbiddenChars.test(value)) {
      return { 'restrictedCharacters': true };
    }
    return null;
  }
}
